import React, { useEffect, useState } from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/js/bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './User/Login';
import Dashboard from './MYPRO/Dashboard';
import Ledger from './MYPRO/Ledger';
import InvoiceUpdateVIew from './MYPRO/InvoiceUpdateVIew';
import Purchase from './MYPRO/Purchase/Purchase';
import CreditNote from './MYPRO/CreditNote/CreditNote';
import DebitNote from './MYPRO/DebitNote/DebitNote';
import PaymentIN from './MYPRO/Receipt/PaymentIN';
import PaymentOut from './MYPRO/Payment/PaymentOut';
import Delete from './Delete';
import App from './App';
import PDFGenerator from './MYPRO/PDFGenerator';
import Setting from './MYPRO/Setting';
import LedgerPop from './MYPRO/LedgerPop';
import Register from './User/Register';
import Profile from './MYPRO/Profile';
import LedgerData from './MYPRO/LedgerData';
import Invoiceaddpanel from './MYPRO/Invoiceaddpanel';
import Journal from './MYPRO/Journal';
import StockJournal from './MYPRO/StockJournal';
import SaleRegister from './Registers/SaleRegister';
import PurchaseRegister from './Registers/PurchaseRegister';
import CreditNoteRegister from './Registers/CreditNoteRegister';
import DebitNoteRegister from './Registers/DebitNoteRegister';
import PaymentRegister from './Registers/PaymentRegister';
import ReceiptRegister from './Registers/ReceiptRegister';
import Mainpage from './MYPRO/Mainpage';
import MyComponent from './MyComponent';
import SalesReport from './SalesReport';
import AddPro from './MYPRO/AddPro';
import axios from 'axios';
import Sale from './MYPRO/Sale/Sale';
import { Authdata } from './Authdata';
import ProductAddrerPanel from './MYPRO/ProductAddrerPanel';
import DashboardCards from './MYPRO/DashboardCards/DashboardCards';
import NumberInput from './MYPRO/NumberInput';
import Purchase_invoice_three from './MYPRO/Purchase/Purchase_invoice_three';
import ProtectedRoute from './ProtectedRoute';
import ReceiptVoucher from './MYPRO/Receipt/ReceiptVoucher';
import InvoiceDummy from './InvoiceDummy';
import Home from './Home';
import FogotPassword from './User/FogotPassword';
import OtpVerify from './User/OtpVerify';
import ChangePassword from './User/ChangePassword';
function RoutesFile() {
  const url="https://subdomain.rupela.in";
  // const url="http://localhost:5000";
  return (
    <div>
      {/* start project after Authdata componet go with url */}
        <Authdata url={url}>
        <BrowserRouter>
            <Routes>
                <Route  path='/dashboard' element={<Dashboard url={url}/>}></Route >
                <Route path='/' element={<Home url={url}/>}></Route>
                {/* <Route path='/sale/:slug' element={<Sale url={url}/> }></Route>
                <Route path='/purchase/:slug' element={<Purchase url={url}/>}></Route>
                <Route path='/credit-note/:slug' element={<CreditNote url={url}/>}></Route>
                <Route path='/debit-note/:slug' element={<DebitNote url={url}/>}></Route>
                <Route path='/payment-in/:slug' element={<PaymentIN url={url}/>}></Route>
                <Route path='/payment-out/:slug' element={<PaymentOut url={url}/>}></Route>
                <Route path='/journal/:slug' element={<Journal url={url}/>}></Route>
                <Route path='/transfer/:inputslug/:outputslug' element={<StockJournal url={url}/>}></Route>
                <Route path='/sale-register' element={<SaleRegister url={url}/>}></Route>
                <Route path='/purchase-register' element={<PurchaseRegister url={url}/>}></Route>
                <Route path='/creditnote-register' element={<CreditNoteRegister url={url}/>}></Route>
                <Route path='/debitnote-register' element={<DebitNoteRegister url={url}/>}></Route>
                <Route path='/payment-register' element={<PaymentRegister url={url}/>}></Route>
                <Route path='/receipt-register' element={<ReceiptRegister url={url}/>}></Route>
                <Route path='/register' element={<Register url={url}/>}></Route>
                <Route path='/ledger' element={<LedgerData url={url}/>}></Route>
                <Route path='/add-pro' element={<AddPro url={url}/>}></Route>
                <Route path='/dashboard/:card' element={<DashboardCards url={url}/>}></Route>
                <Route path='forgot-password' element={<FogotPassword url={url}/>}></Route> */}
                {/* <Route path='/otp-verify/:email' element={<OtpVerify url={url}/>}></Route> */}
                {/* <Route path='/forgot-password/:email' element={<ChangePassword url={url}/>}></Route> */}
            </Routes>
        </BrowserRouter>
        </Authdata>
    </div>
  )
}

export default RoutesFile
