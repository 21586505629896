import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encryptData, decryptData } from '../encryptionUtils';
function Login({url,  ChangeNavRed}) {
    // this state is useing get data input fileds
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // this state use validate data get input fields
    const [error, setError] = useState({
        email: "",
        password: ""
    })

    //this funcation use redirect page task successfuly perform
    const redirect = useNavigate();

    //this useeffect use page load then get token and redirect dashboar other wise not work
    useEffect(() => {
        const auth = localStorage.getItem("token");
        if (auth) {
            redirect('/dashboard');
        }
    }, [])


    //this is a login funtction
    const Login = async () => {

        //first inisalise validation Errors variable
        let validationError = {};

        //second check email and password is not empty
        if (!email.trim() && !password.trim()) {
            validationError.email = "Email is required";
            validationError.password = "Password is required";
        } 
        
        // singal check password is not empty
        else if (!password.trim()) {
            validationError.password = "Password is required";
        } 
        
        //single check email is not empty
        else if (!email.trim()) {
            validationError.email = "Email is required";
        }

        //setError state  all validations 
        setError(validationError);

        //if condition check validation object length is zero
        if (Object.keys(validationError).length === 0) {

            //this data variable use get value in object
            let data = { email, password };


            //this variable use api call and check login send data 
            const res = await axios.post(`${url}/login`, data);
            //this if condition check res vaiable data not equal to Accout Active and check Accoutn Invalid
            if (res.data && res.data !== 'account-is-not-active' && res.data !== 'invalid-credentials' && res.data !== 'invalid-password') {
                if (res.data.token) {
                    localStorage.setItem("token", JSON.stringify({ token: res.data.token }));
                    localStorage.setItem("setting", JSON.stringify({
                        bosno: "automatic",
                        invoiceAlignment: {
                            logo: "center",
                            firm_name: "center",
                            address: "center",
                            show_logo: "block",
                            show_firm_name: "block",
                            show_address: "block",
                            show_desc: "block"
                        }
                    }));
                    redirect('/dashboard');

                } else {
                    console.error('Token is missing in the response');
                }
                // Redirect to dashboard
            } else if (res.data === "account-is-not-active") {
                toast.info('Account is not active');
            } else if (res.data === "invalid-password") {
                toast.error('Wrong Password');
            } else if (res.data === "invalid-credentials") {
                toast.error('Invalid Account');
            } else {
                toast.error('Unexpected error occurred');
            }
        }

    }
    return (
        <>
            <div className='container-fluid vh-100'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <div className='col-md-5 '>
                        <div class="card ">
                            <div className='card-header d-flex bg-primary text-white'>
                                {/* <span class="navbar-brand text-uppercase me-5" href="#">Invoice Platform</span> */}
                                <span class="nav-link start-50" aria-current="page" href="">Login</span>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)}></input>
                                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                    <div>{error.email ? <span className='text-danger'>{error.email}</span> : null}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)}></input>
                                    <div>{error.password ? <span className='text-danger'>{error.password}</span> : null}</div>
                                    <Link  onClick={()=>ChangeNavRed('forgot-password')}>Forgot password?</Link>
                                </div>
                                <div className='mb-3 text-end'>
                                    <Link onClick={()=>ChangeNavRed("register")} className='btn btn-outline-primary'>Create Account</Link>
                                    &nbsp;<button class="btn btn-primary" onClick={Login}>Login</button>
                                </div>
                            </div>
                            <ToastContainer />
                        </div>
                        <div className='row text-end' >
                            {/* <Link to={"/register"}>SignUp</Link> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Login
