import axios from 'axios';
import React, { useState } from 'react'
import { ToastContainer } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function ChangePassword({url,mail, ChangeNavRed}) {
    const [email, setEmail] = useState(mail);
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const redirect=useNavigate();
    const Changepassworduser = async () => {
        if(password==confirmpassword){
            const data={email,password};
            const res=await axios.post(`${url}/update-password`,data);
            if(res.data){
                toast.success("Password is Change successfully");
                ChangeNavRed('login');
            }
        }else{
            toast.error("Password is not matich");
        }
        
    }
  return (
    <div>
       <div className='container-fluid'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <div className='col-md-5 '>
                        <div class="card ">
                            <div className='card-header d-flex bg-primary text-white'>
                                {/* <span class="navbar-brand text-uppercase me-5" href="#">Invoice Platform</span> */}
                                <span class="nav-link start-50" aria-current="page" href="">Change Password</span>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} disabled></input>
                                    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">New Password</label>
                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Create New Password' onChange={(e) => setPassword(e.target.value)}></input>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Confirm Password</label>
                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)}></input>
                                </div>
                               
                                <button class="btn btn-primary" onClick={Changepassworduser}>Reset Password</button>
                            </div>
                        </div>
                        <div className='row text-end' >
                        </div>
                    </div>
                </div>
            <ToastContainer/>
            </div>
    </div>
  )
}

export default ChangePassword
