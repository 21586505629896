import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import OtpVerify from './OtpVerify';
import ChangePassword from './ChangePassword';

function FogotPassword({ url, ChangeNavRed }) {
    const [email, setEmail] = useState("");
    const redirect = useNavigate();
    const [disable, setDisable] = useState(false);
    const [changesComponet, setChangesComponet] = useState("forgot-password");
    const sendMail = async (e) => {
        e.preventDefault();
        setDisable(true);
        const res = await axios.post(`${url}/forgotpassword`, { email: email });
        if (res.data) {
            setChangesComponet('otp-verify');
        } else {
            toast.error('Invalid Mail')
        }
    }
    return (
        <div className='vh-100'>
            {
                changesComponet == "forgot-password" ?
                    <div className='container '>
                        <div className='row mt-5'>
                            <div className='col-12 text-center' >
                                <i class="bi bi-shield-lock-fill"></i>
                            </div>
                            <div className='col-12 text-center'>
                                <h1>Forgot Password?</h1>
                                <p>You can reset your password here.</p>
                            </div>
                            <form onSubmit={sendMail}>
                                <div className='offset-3 col-6'>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-envelope"></i></span>
                                        <input type="email" class="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className='offset-3 col-6'>
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-primary" type="submit" disabled={disable ? true : false}>Reset Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    :changesComponet=="otp-verify"?<OtpVerify url={url} email={email} ChangeNavRed={ChangeNavRed}/>:null
            }

            <ToastContainer />
        </div>
    )
}

export default FogotPassword
