import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { encryptData, decryptData } from '../encryptionUtils';
// import { authdata } from '../Authdata';
import { useUser } from '../Authdata';
function Profile({ url, componetupdate }) {
  const { auth, reloadpage } = useUser();
  const [frm_name, setFrmName] = useState("");
  const [fssai, setFssai] = useState("");
  const [udyam, setUdyam] = useState("");
  const [udyamState, setUdyamState] = useState("");
  const [udyamDistrict, setUdyamDistrict] = useState("");
  const [udyamNumber, setUdyamNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState();
  const [date, setDate] = useState("");
  const [password, setPassword] = useState("");
  const [gstin, setGSTIN] = useState("");
  const redirect = useNavigate();
  const [myinputs, setMyInputs] = useState([{ Name: "", Value: "" }]);
  const [error, setError] = useState({
    frm_name: "",
    fssai: "",
    udyam: "",
    mobile: "",
    address: "",
    email: "",
    date: "",
    gstin: ""
  })
  useEffect(() => {
    axios.get(`${url}/user/${id}`).then((res) => {
      setFrmName(res.data.frm_name);
      setEmail(res.data.email);
      setFssai(res.data.fssai);
      setUdyam(res.data.udyam);
      setAddress(res.data.address);
      setMobile(res.data.mobile);
      setGSTIN(res.data.gstin);
      const d = new Date(res.data.date);
      const dd = String(d.getDate()).padStart(2, '0');
      const mm = String(d.getMonth() + 1).padStart(2, '0');
      const yyyy = d.getFullYear();
      setDate(`${yyyy}-${mm}-${dd}`);
      setLogo(res.data.logo);
      setPassword(res.data.password)
      setMyInputs(res.data.my_filed?JSON.parse(res.data.my_filed):[{ Name: "", Value: "" }]);
    })
    
  }, [auth.id, url])
  const userupdate = (e) => {
    e.preventDefault();
    let validateionerror = {};
    if (!frm_name.trim()) {
      validateionerror.frm_name = "Hotel name is required";
    }
    if (fssai) {
      if (!fssai.trim() && !/^\d{15}$/.test(fssai)) {
        validateionerror.fssai = "FSSAI number must be 15 digits";
      }
    }
    if (udyam) {
      if (!udyam.trim() && !/^UDYAM-\w{2}-\d{2}-\d{7}$/.test(udyam)) {
        validateionerror.udyam = "UDYAM number must follow the format UDYAM-STATE CODE-DISTRICT CODE-7DIGITS";
      }
    }
    if (!mobile.trim() && !/^\d{10}$/.test(mobile)) {
      validateionerror.mobile = "Mobile number must be 10 digits";
    }
    if (!address.trim()) {
      validateionerror.address = "Address is required";
    }
    if (!email.trim() || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      validateionerror.email = "Valid email is required";
    }
    // if (!gstin.trim() && !/^[A-Z0-9]{15}$/.test(gstin)) {
    //   validateionerror.gstin = "GSTIN must be 15 characters long and contain only uppercase letters and numbers.";
    // }
    if (!date.trim()) {
      validateionerror.date = "Date is required";
    }

    setError(validateionerror)
    if (Object.keys(validateionerror).length === 0) {
      const formdata = new FormData();
      formdata.append("frm_name", frm_name);
      formdata.append("fssai", fssai);
      formdata.append("udyam", udyam);
      formdata.append("mobile", mobile);
      formdata.append("address", address);
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("gstin", gstin);
      formdata.append("my_filed",JSON.stringify(myinputs));
      if (typeof (logo) == "string") {
        formdata.append("logo", logo);
      } else {
        formdata.append("file", logo);
      }
      formdata.append("date", date);
      axios.put(`${url}/userupdate/${id}`, formdata).then((res) => {
        if (res.data) {
          console.log(res.data);
          
          // toast.success("User profile update successfuly")
          // reloadpage();
        }
      })
    }


  }
  const changeCase = (event) => {
    event.preventDefault();
    setFrmName(event.target.value.toUpperCase());
  };
  $("#myBox").modal({
    backdrop: "static",
    keyboard: false,
  });

  let id = auth && auth.id
  const handleAddInput = () => {
    setMyInputs([...myinputs, { Name: "", Value: "" }]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...myinputs];
    onChangeValue[index][name] = value;
    setMyInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...myinputs];
    newArray.splice(index, 1);
    setMyInputs(newArray);
  };

  return (
    <div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Profile</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={userupdate}>
            <div class="modal-body" style={{ overflow: "scroll", height: "450px" }}>
              <div>
                <div class="card-body " >
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Firm Name</label>
                    <input type="text" class="form-control" value={frm_name} onChange={(e) => changeCase(e)}></input>
                    <div>{error.frm_name && <span className='text-danger'>{error.frm_name}</span>}</div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Firm Logo</label>
                    <input type="file" class="form-control" onChange={(e) => setLogo(e.target.files[0])} accept={'.jpg, .png, .jpeg, .webp'}></input>
                    <img src={`${url}/upload/${auth && auth.logo}`} height={"100px"}></img>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Firm Opening Date</label>
                    <input type="date" class="form-control" onChange={(e) => setDate(e.target.value)} value={date}></input>
                    <div>{error.date && <span className='text-danger'>{error.date}</span>}</div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">FSSAI</label>
                    <input type="text" class="form-control" value={fssai} onChange={(e) => setFssai(e.target.value)}></input>
                    <div>{error.fssai && <span className='text-danger'>{error.fssai}</span>}</div>

                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">UDYAM</label>
                    <input type="text" class="form-control" value={udyam} onChange={(e) => setUdyam(e.target.value)}></input>
                    <div>{error.udyam && <span className='text-danger'>{error.udyam}</span>}</div>

                  </div>
                  <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">Mobile</label>
                    <input type="text" className="form-control" pattern="[0-9]{10}" maxLength="10" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                    <div>{error.mobile && <span className='text-danger'>{error.mobile}</span>}</div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Address</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setAddress(e.target.value)} value={address}></textarea>
                    <div>{error.address && <span className='text-danger'>{error.address}</span>}</div>

                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" value={email} onChange={(e) => setEmail(e.target.value)} disabled></input>
                    <div class="form-text">We'll never share your email with anyone else.</div>
                    <div>{error.email && <span className='text-danger'>{error.email}</span>}</div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">GSTIN</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" value={gstin} pattern="[A-Z0-9]{0,15}" maxlength="15" title="Only uppercase letters (A-Z) and numbers are allowed, with a maximum length of 15 characters." onChange={(e) => setGSTIN(e.target.value)} ></input>
                    <div>{error.gstin && <span className='text-danger'>{error.gstin}</span>}</div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Your Filed</label>
                    <div className="container">
                      <div className="row">

                        {myinputs.map((item, index) => (
                          <>
                            <div className="col-md-12 mb-3 input_container" key={index}>
                              <div className="d-flex align-items-center">
                                {myinputs.length > 1 && (
                                  <button
                                    className="btn btn-danger me-3"
                                    onClick={() => handleDeleteInput(index)}
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>
                                )}
                                <input
                                  name="Name"
                                  type="text"
                                  className="form-control me-2"
                                  placeholder="Filed Name"
                                  value={item.Name}
                                  style={{ width: "40%" }}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <input
                                  name="Value"
                                  type="text"
                                  className="form-control me-2"
                                  placeholder="Filed Value"
                                  value={item.Value}
                                  onChange={(event) => handleChange(event, index)}
                                />

                              </div>
                            </div>
                            {index === myinputs.length - 1 && (
                              <div className='text-end'><button
                                className="btn btn-primary"
                                onClick={() => handleAddInput()}
                              >
                                <i class="bi bi-plus-lg"></i>
                              </button></div>
                            )}
                          </>
                        ))}
                      </div >


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button class="btn btn-success" type='submit' data-bs-dismiss={Object.keys(error).length === 0 ? "modal" : ""}>Update Register</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default Profile