import axios from 'axios';
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const GlobalData = createContext();
import MyPro from './MyPro';
import Ledger from './Ledger';
import Sale from './Sale/Sale';
import PaymentIN from './Receipt/PaymentIN';
import Purchase from './Purchase/Purchase';
import PaymentOut from './Payment/PaymentOut';
import CreditNote from './CreditNote/CreditNote';
import DebitNote from './DebitNote/DebitNote';
import Journal from './Journal';
import StockJournal from './StockJournal';
import Mainpage from './Mainpage';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import Setting from './Setting';
// import './Dashboard.css';
function Dashboard(props) {

    //this is states
    const [url, setUrl] = useState(`${props.url}`);
    const { auth, Authclear, reloadpage } = useUser();
    const [myComponent, setMyComponent] = useState("");
    const [profileupdate, setProfileUpdate] = useState(0);
    const setting = JSON.parse(localStorage.getItem("setting"));
    const [settingcontrol, setSettingControl] = useState(true);
    const redirect = useNavigate();
    const navButton = useRef(null);
    const linksContainerRef = useRef(null);
    const [activeLink, setActiveLink] = useState("");


    //this is useEffect reloadpage and setMycomponet state
    //profile state update then reload page
    // user data update then reload page
    useEffect(() => {
        reloadpage();
        setMyComponent("");
    }, [profileupdate, useUser])

    //this is logout function 
    //this function use to clean localstore token and settings
    //redirect home/login page
    const logout = () => {
        localStorage.clear();
        Authclear();
        redirect("/");
    }


    //this finction use componet canges like sale to purchese
    function setNavbar(val) {
        setMyComponent(val)
        // navButton.current.classList.add("collapsed");
        // linksContainerRef.current.classList.remove("show");
    }

    //this function use to navbar button click active and change componet
    const handleNavClick = (navItem) => {
        setActiveLink(navItem);
        setNavbar(navItem);
    };

    const SettingStateChange=()=>{
        console.log("stateChange");
        
        setSettingControl(!settingcontrol)
    }



    //this is ui statup
    return (
        <div>

            {/* this is shortcut keys componet */}
            <GlobalKeyListener />


            {/* this  code start dasnbord */}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 bg-light'>

                        {/* --navbar start-- */}
                        <nav class="navbar navbar-light bg-light">
                            <div class="container-fluid">
                                {/* this is frm logo  */}
                                <div className='navbar-brand  d-flex'>
                                    <img  src={`${props.url}/upload/${auth && auth.logo}`} height={"50px"} alt="Logo"></img>&nbsp;
                                    {/* this is frm name set with onclick go to  dashnord event */}
                                    <Link className="text-dark text-decoration-underline" style={{marginTop:"7px"}} onClick={() => handleNavClick("")}><h3>{auth && auth.frm_name}</h3></Link>
                                </div>
                                <div class="d-flex">
                                    <div className='me-2'>
                                        <Setting url={url} settingStateChange={SettingStateChange} />
                                    </div>
                                    <div className='me-2'>
                                        <Link className="nav-link d-md-inline d-none" to={"/"} onClick={logout}><i className="bi bi-box-arrow-right "></i>&nbsp;Logout</Link>
                                        <Link className="nav-link d-block d-md-none" to={"/"} onClick={logout}><i className="bi bi-box-arrow-right "></i>&nbsp;</Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    
                        {/* --navbar end-- */}
                    </div>
                    {/* --componets buttons-- */}
                    <div className="col-sm-12 bg-primary ">
                        <div className="d-md-none d-block">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasExample"
                                aria-controls="offcanvasExample"
                            >
                                <i className="bi bi-list"></i>
                            </button>

                            <div
                                className="offcanvas offcanvas-start bg-primary"
                                tabIndex="-1"
                                id="offcanvasExample"
                                aria-labelledby="offcanvasExampleLabel"
                            >
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><img src={`${props.url}/upload/${auth && auth.logo}`} height={"50px"} alt="Logo"></img>&nbsp;<span className={"text-decoration-underline"}>{auth && auth.frm_name}</span></h5>
                                    <button
                                        type="button"
                                        className="btn-close text-reset"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    ></button>
                                </div>

                                <div className="offcanvas-body ">
                                    <ul
                                        className="list-unstyled d-flex flex-column align-items-center justify-content-center"
                                        style={{ height: "auto", marginBottom: "-1px", padding: "0" }}
                                    >
                                        {[
                                            { name: "Dashboard", link: "" },
                                            { name: "Sale", link: "sale" },
                                            { name: "Receipt", link: "paymentin" },
                                            { name: "Purchase", link: "purchase" },
                                            { name: "Payment", link: "paymentout" },
                                            { name: "Credit Note", link: "creditnote" },
                                            { name: "Debit Note", link: "debitnote" },
                                            { name: "Journal", link: "journal" },
                                            { name: "Inventory", link: "inventory" },
                                            { name: "Stock Journal", link: "stock-journal" }
                                        ].map((item) => (
                                            <li
                                                className="w-100 d-flex justify-content-center mb-2"
                                                key={item.link}
                                            >
                                                <button
                                                    className={`btn custom-btn w-100 ${activeLink === item.link ? "custom-active" : ""}`}
                                                    onClick={() => handleNavClick(item.link)}
                                                    data-bs-dismiss="offcanvas"

                                                >
                                                    {item.name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='d-none d-md-block'>


                            <ul className='d-md-flex ' style={{ marginBottom: "-1px" }}>
                                {[
                                    { name: "Dashboard", link: "" },
                                    { name: "Sale", link: "sale" },
                                    { name: "Receipt", link: "paymentin" },
                                    { name: "Purchase", link: "purchase" },
                                    { name: "Payment", link: "paymentout" },
                                    { name: "Credit Note", link: "creditnote" },
                                    { name: "Debit Note", link: "debitnote" },
                                    { name: "Journal", link: "journal" },
                                    { name: "Inventory", link: "inventory" },
                                    { name: "Stock Journal", link: "stock-journal" }
                                ].map((item) => (
                                    <li className="d-flex text-decoration-none" key={item.link} style={{ flex: 1 }}>
                                        <button
                                            className={`btn custom-btn w-100 ${activeLink === item.link ? "custom-active" : ""}`}
                                            onClick={() => handleNavClick(item.link)}
                                        >
                                            {item.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* --end componets button-- */}

                </div>
                {/* componets-dashboard,sale,purchase,creditnote,Recept,debitnote,payment,inventery,journal,stockjournal*/}
                <div className='row'>
                    <div className='col-md-12  bg-light vh-100'>
                        <nav className="navbar navbar-expand-lg navbar-light bg-light"></nav>
                        <div className='container-fluid bg-white rounded shadow'>
                            {/* this is all componet  */}
                            {
                                myComponent == "ledger" ? <Ledger url={url} />
                                    : myComponent == "sale" ? <Sale url={url} bosno={setting.bosno} settingcontrol={settingcontrol} updateinfo={{}}/>
                                        : myComponent == "paymentin" ? <PaymentIN url={url} settingcontrol={settingcontrol} />
                                            : myComponent == 'purchase' ? <Purchase url={url} bosno={setting.bosno} settingcontrol={settingcontrol} updateinfo={{}}/>
                                                : myComponent == 'paymentout' ? <PaymentOut url={url} updateinfo={{}}/>
                                                    : myComponent == 'creditnote' ? <CreditNote url={url} bosno={setting.bosno} settingcontrol={settingcontrol} updateinfo={{}}/>
                                                        : myComponent == "debitnote" ? <DebitNote url={url} bosno={setting.bosno} settingcontrol={settingcontrol} updateinfo={{}}/>
                                                            : myComponent == "journal" ? <Journal url={url} updateinfo={{}}/>
                                                                : myComponent == "inventory" ? <MyPro url={url} />
                                                                    : myComponent == "stock-journal" ? <StockJournal url={url} />
                                                                        : <Mainpage url={url} setting={settingcontrol}/>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}

export default Dashboard
