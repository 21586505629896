import React, { useEffect, useState } from 'react'
import { useUser } from '../../Authdata';
import axios from 'axios';

function StockDashboardCard(props) {
    const { auth, reloadpage } = useUser();
    const [stock, setStock] = useState([]);
    const [stocktotal, setStockTotal] = useState(0);
    useEffect(() => {
        const getData = async () => {
            const response = await axios.get(`${props.url}/show_products/${auth.id}_product/${auth.id}_stock`);
            const data = response.data;
            const info = [];
            data.forEach(element => {
                let obj = {
                    stockname: element.name,
                    saleprice: element.sale_price,
                    purchaseprice: element.purchase_price,
                    unit: `${element.quantity} ${element.unit}`,
                    stockvalue: element.quantity * element.purchase_price
                }
                info.push(obj)
            });
            let totalstockprice = 0;
            for (const e of data) {
                const purprice = e.purchase_price * e.quantity;
                totalstockprice = totalstockprice + parseFloat(purprice);
            }
            setStockTotal(totalstockprice);
            setStock(info);
        }
        getData();
    }, [])
    return (
        <div>

            <div className='container'>
                <div className='row m-5 text-center'>
                    <div className='col-12'><h1 className='text-decoration-underline'>Stocks</h1></div>
                </div>
                <div className='row'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Stock Name</th>
                                <th scope="col">Sale Price</th>
                                <th scope="col">Purchase Price</th>
                                <th scope="col">Unit</th>
                                <th scope='col'>Stock Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stock.map((info) =>
                                    <tr>
                                        <td>{info.stockname}</td>
                                        <td>{info.saleprice}</td>
                                        <td>{info.purchaseprice}</td>
                                        <td>{info.unit}</td>
                                        <td>{info.stockvalue}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>Total</th>
                                <th>{stocktotal}</th>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <button className='btn btn-dark' onClick={()=>props.onMoveCard("")} id="printfun"><i class="bi bi-arrow-left-short"></i> Back</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockDashboardCard
