import React, { useEffect, useState } from 'react'
import { useUser } from '../../Authdata';
import { Link } from 'react-router-dom';
function Invoice_one({ url, bosNo, datelocal, billing, alldataIndireact_direact, discount, total, wordstotal, debtarname, totalamount, deleteIndex, editItem, BillControler, invoiceAlignmentmeng, setting }) {
    const { auth } = useUser();
    const settingData=JSON.parse(localStorage.getItem("setting"));
    const [deleteIndexno, setDeleteIndexNo] = useState("");
    const [invoiceFileds,setInputFileds]=useState();
    
    deleteIndex(deleteIndexno);
    const editbillItem=(val)=>{
        editItem(val,"editstart")
    }

    return (
        <div >
            <div class="card" >
                {/* <div className='bg-dark text-white text-uppercase text-center'>Invoice</div> */}
                <div class="card-body mx-4">
                    <div class="container">
                        <div className={`row d-${invoiceAlignmentmeng.show_logo}`}>
                            <div className={`text-${invoiceAlignmentmeng.logo}  col-12`}>
                                <img src={`${url}/upload/${auth && auth.logo}`} height={"80px"}></img>
                            </div>
                        </div>
                        <p class={`text-${invoiceAlignmentmeng.firm_name} d-${invoiceAlignmentmeng.show_firm_name} m-0`} style={{ fontSize: "30px" }}>{auth.frm_name}</p>
                        <p class={`text-${invoiceAlignmentmeng.address} d-${invoiceAlignmentmeng.show_address} m-0`} >{auth.address}</p>
                        
                        <span class={`text-${invoiceAlignmentmeng.address} d-${invoiceAlignmentmeng.show_address} m-0`} >{auth.mobile}</span> || &nbsp;
                        <span class={`text-${invoiceAlignmentmeng.address} d-${invoiceAlignmentmeng.show_address} m-0`} >{auth.email}</span>
                        {
                            invoiceFileds && invoiceFileds.map((e)=>
                                <>
                                    <p>{e.Name}:{e.Value}</p>
                                </>
                            )
                        }
                        <div class="row mt-2">
                            <table>
                                <tr className=''>
                                    <th>BOS No:{bosNo}</th>
                                    <th >Date:{datelocal}</th>
                                </tr>
                                <tr>
                                    <td><b>{debtarname.name}</b></td>
                                </tr>
                            </table>
                            <hr></hr>
                            <table className='table'>
                                <tr>
                                    <th >Particulars</th>
                                    <th >Qty</th>
                                    <th >Unit</th>
                                    <th >Rate</th>

                                    <th >Amount</th>
                                    <th ></th>
                                </tr>
                                {
                                    billing && billing.map((item, index) =>
                                        <tr >
                                            <td >{item.particular}</td>
                                            <td >{item.quaty}</td>
                                            <td >{item.unit}</td>
                                            <td >{item.rate}</td>
                                            <td >{item.Amout}</td>
                                            <td >
                                                <Link className='hide-print' onClick={() => setDeleteIndexNo({ pro_id: item.pro_id, index, item_id: item.item_id })}><i className='bi bi-trash-fill'></i></Link>
                                                <Link className='hide-print' onClick={() => editbillItem({ pro_id: item.pro_id, index, item_id: item.item_id })}><i className='bi bi-pen-fill'></i></Link>
                                                {/* <input type='radio' name='deleteitem' onClick={(e) => setDeleteIndexNo(e.target.value)} value={index}></input> */}
                                            </td>
                                        </tr>

                                    )

                                }
                                {
                                    totalamount != 0 ?
                                        <>
                                            <tr className="middle-line">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{total}</td>
                                            </tr>
                                        </>
                                        : null
                                }
                                {
                                    alldataIndireact_direact && alldataIndireact_direact.map((item) =>
                                        <tr>
                                            <td >{item.particular}</td>
                                            <td ></td>
                                            <td ></td>
                                            <td >{item.percentage != "" ? `${item.percentage}%` : null}</td>
                                            <td >{item.percentage != "" ? discount : item.amount}</td>
                                        </tr>
                                    )
                                }
                            </table>
                            <hr style={{ border: "2px solid black" }}></hr>
                        </div>


                        <div className='row'>
                            <table>
                                <tr >
                                    <th class="float-start fw-normal">Total Amount in Words:&nbsp;<span className='fw-bold'>&nbsp;{wordstotal}</span></th>
                                    <th class="float-end fw-bold ">Total:&#8377;{totalamount != 0 ? totalamount : total}</th>
                                </tr>
                            </table>
                            <hr style={{ border: "2px solid black" }}></hr>
                        </div>
                        <div className='row'>
                            <div className='col'>FSSAI NO:</div>
                            <div className='col'>{auth.fssai}</div>
                        </div>
                        <div class="text-center" style={{ marginTop: "20px" }}>
                            <u class="text-dark">Thank You Visit Again </u>
                            <p >Please Check The Bill Before Making The Payment
                                No Complaints Will Be Entertain Thereafter
                            </p>
                            {/* <p class="text-start">SRI GANESHAY NAMAH</p> */}
                        </div>

                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default Invoice_one