import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function Register({ url, ChangeNavRed }) {

    // all states 
    const [frm_name, setHotelName] = useState("");
    const [fssai, setFssai] = useState("");
    const [udyam, setUdyam] = useState("UDYAM");
    const [udyamState, setUdyamState] = useState("");
    const [udyamDistrict, setUdyamDistrict] = useState("");
    const [udyamNumber, setUdyamNumber] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logo, setLogo] = useState();
    const [date, setDate] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const redirect = useNavigate();
    const [loading, setLoading] = useState(false);
    const [gstin, setGSTIN] = useState("");
    const [error, setError] = useState({
        frm_name: "",
        file: "",
        fssai: "",
        udyam: "",
        mobile: "",
        address: "",
        email: "",
        password: "",
        confirmpassword: "",
        date: "",
        gstin:""
    });


    const register = async (e) => {
        e.preventDefault();
        let validationError = {};

        if (!frm_name.trim()) {
            validationError.frm_name = "Business name is required";
        }
        if (!logo) {
            validationError.file = "File upload is required";
        }
        if (fssai) {
            if (!fssai.trim()) {
                validationError.fssai = "FSSAI number is required";
            } else if (!/^\d{15}$/.test(fssai)) {
                validationError.fssai = "FSSAI number must be 15 digits";
            }
        }
        if (udyam && udyamState && udyamDistrict && udyamNumber) {

            if (!udyamState.trim() || !udyamDistrict.trim() || !udyamNumber.trim()) {
                validationError.udyam = "Complete UDYAM number is required";
            } else if (!/^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/.test(udyam + "-" + udyamState + "-" + udyamDistrict + "-" + udyamNumber)) {
                validationError.udyam = "UDYAM number must follow the format UDYAM-STATE CODE-DISTRICT CODE-7DIGITS";
            }
        }

        if (!mobile.trim()) {
            validationError.mobile = "Mobile number is required";
        } else if (!/^\d{10}$/.test(mobile)) {
            validationError.mobile = "Mobile number must be 10 digits";
        }
        if (!address.trim()) {
            validationError.address = "Address is required";
        }
        if (!email.trim()) {
            validationError.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            validationError.email = "Email format is invalid";
        }
        if (!date.trim()) {
            validationError.date = "Date is required";
        }
        if (!password.trim()) {
            validationError.password = "Password is required";
        }
        if (!confirmpassword.trim()) {
            validationError.confirmpassword = "Confirm password is required";
        } else if (password !== confirmpassword) {
            validationError.confirmpassword = "Passwords do not match";
        }
        if (gstin && !/^[A-Z0-9]{15}$/.test(gstin)) {
            validationError.gstin = "GSTIN must be 15 characters long and contain only uppercase letters and numbers.";
        }

        setError(validationError);

        const udyamno = `${udyam}-${udyamState}-${udyamDistrict}-${udyamNumber}`;
        if (Object.keys(validationError).length === 0) {
            setLoading(true)
            const formData = new FormData();
            formData.append("frm_name", frm_name);
            formData.append("fssai", fssai);
            formData.append("udyam", udyamno);
            formData.append("mobile", mobile);
            formData.append("address", address);
            formData.append("email", email);
            formData.append("file", logo);
            formData.append("date", date);
            formData.append("password", password);
            formData.append("gstin", gstin);
            let res = await axios.post(`${url}/register`, formData);
            if (res.data) {
                if (res.data === "ER_DUP_ENTRY") {
                    toast.error("Email already exists");
                } else {
                    setLoading(false);
                    ChangeNavRed("login")
                    // redirect("/"); // Assuming redirect function exists
                }
            } else {
                console.log(res); // Log response for debugging
            }


        }
    };

    const changeCase = (event) => {
        event.preventDefault();
        setHotelName(event.target.value.toUpperCase());
    };

    return (
        <div>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='row mt-5 d-flex justify-content-center mb-5'>
                    <div className=''>
                        <form onSubmit={register}>
                            <div className="card ">
                                <div className='card-header d-flex bg-primary text-white'>
                                    {/* <span className="navbar-brand text-uppercase me-5">Invoice Platform</span> */}
                                    <span className="nav-link start-50" aria-current="page">Registration</span>
                                </div>
                                <div className="card-body col-md-12 " >
                                    <div className='container'>
                                        <div className='row'>
                                            <div className="col-md-3 mb-3">
                                                <label className="form-label">Business Name</label>
                                                <input type="text" className="form-control" value={frm_name} onChange={changeCase} placeholder="Business Name" required title="Business Name is Required" />
                                                {error.frm_name && <span className='text-danger'>{error.frm_name}</span>}
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label className="form-label">Business Logo</label>
                                                <input type="file" className="form-control" onChange={(e) => setLogo(e.target.files[0])} required accept=".png, .jpg, .jpeg" title="Logo is Required accept jpg,png,jpeg" />
                                                {error.file && <span className='text-danger'>{error.file}</span>}
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label className="form-label">Business Opening Date</label>
                                                <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} required />
                                                {error.date && <span className='text-danger'>{error.date}</span>}
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label className="form-label">FSSAI</label>
                                                <input type="text" className="form-control" onChange={(e) => setFssai(e.target.value)} title='FSSAI number must be 15 digits' maxLength={"15"} minLength={"1"} placeholder="FSSAI" />
                                                {error.fssai && <span className='text-danger'>{error.fssai}</span>}
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">UDYAM</label>
                                                <div className="d-md-flex">
                                                    <input type="text" className="form-control me-2 mb-2" value={udyam} disabled />
                                                    <input type="text" className="form-control me-2 mb-2" maxLength={"2"} placeholder="State Code" onChange={(e) => setUdyamState(e.target.value.toUpperCase())} />
                                                    <input type="text" className="form-control me-2 mb-2" maxLength={"2"} placeholder="District Code" onChange={(e) => setUdyamDistrict(e.target.value)} />
                                                    <input type="text" className="form-control" maxLength={"7"} placeholder="7 Digits" onChange={(e) => setUdyamNumber(e.target.value)} />
                                                </div>
                                                {error.udyam && <span className='text-danger'>{error.udyam}</span>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Mobile</label>
                                                <input type="text" className="form-control" onChange={(e) => setMobile(e.target.value)} required title="" maxLength={"10"} minLength={"1"} placeholder="Mobile" />
                                                {error.mobile && <span className='text-danger'>{error.mobile}</span>}
                                                <label for="gstin" class="form-label mt-1">GSTIN</label>
                                                <input type="text" class="form-control" id="gstin" value={gstin} placeholder='Enter a GSTIN' pattern="[A-Z0-9]{0,15}" maxlength="15" title="Only uppercase letters (A-Z) and numbers are allowed, with a maximum length of 15 characters." onChange={(e) => setGSTIN(e.target.value)} ></input>
                                                <div>{error.gstin && <span className='text-danger'>{error.gstin}</span>}</div>
                                            </div>
                                           
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Address</label>
                                                <textarea className="form-control" rows="4" onChange={(e) => setAddress(e.target.value)} placeholder="Address" required title="Address Is Required"></textarea>
                                                {error.address && <span className='text-danger'>{error.address}</span>}
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">Email address</label>
                                                <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder='Email' required title="Email is Required" />
                                                <div className="form-text">We'll never share your email with anyone else.</div>
                                                {error.email && <span className='text-danger'>{error.email}</span>}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} placeholder="Password" required title="Email is Required" />
                                                {error.password && <span className='text-danger'>{error.password}</span>}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Confirm Password</label>
                                                <input type="password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required title="Confirm Password Required" />
                                                {error.confirmpassword && <span className='text-danger'>{error.confirmpassword}</span>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='card-foot m-3 text-end'>
                                    <Link
                                     class="btn btn-outline-primary" onClick={()=>ChangeNavRed("login")}>Login</Link>&nbsp;
                                    <button className="btn btn-primary" type='submit' disabled={loading ? true : false}>
                                        Register
                                        {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : null
                                        }</button>
                                </div>
                            </div>
                        </form>
                        {/* <div className='row text-end'>
                            <Link to="/">Login</Link>
                        </div> */}
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default Register;
