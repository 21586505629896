import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GlobalKeyListener from '../GlobalKeyListener';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { encryptData, decryptData } from '../encryptionUtils';
import { useUser } from '../Authdata';
function Ledger({url, ledgerviewsdata, updateinvoicecomponet, update_and_back}) {
    const [ledgerinfo,setLedgerInfo]=useState(JSON.parse(ledgerviewsdata));
    const { auth } = useUser();
    const [sale, setSale] = useState([]);
    const [purchase, setPurchase] = useState([]);
    const [ledgerdata, setLedgerdata] = useState([]);
    const [ledgername, setLedgername] = useState("");
    const [data, setData] = useState([]);
    const [receiptdata, setReceiptdata] = useState([]);
    const [payment, setPayment] = useState([]);
    const [creditnote, setCreditNote] = useState([]);
    const [debitnote, setDebitNote] = useState([]);
    const [journal, setJournal] = useState([]);
    const [alldata, setAlldata] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [closeblance, setCloseBalance] = useState(0);
    const [openingblance, setOpeningBalance] = useState(0);
    const [call, setCall] = useState(null);
    const printref = useRef();
    // const []
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [saledata, setSaleData] = useState([]);


    const redirect = useNavigate();
    useEffect(() => {
        APICalling();
    }, [])
    const APICalling = async () => {
        const res = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
        setLedgerdata(res.data);
    }
    useEffect(() => {
        // console.log(data);
        Invoicedatafilter(ledgerviewsdata);
    }, [ledgerviewsdata])
    const Invoicedatafilter = async (val) => {
        val = JSON.parse(val);
        setLedgername(val.name)
        if (val.id) {
            let res = await axios.get(`${url}/finder/${auth.id}/${val.id}`);
            setData(res.data);
            let alldata = [
                {
                    date: null,
                    particulars: "Opening Balance",
                    debit: null,
                    credit: null,
                    balance: 0,
                    vochertype: null,
                    val: null,
                    vocher: null,
                }
            ];
            res && res.data.forEach((invinfo) => {
                let id = invinfo.id;
                let particul = '';
                let credit = '';
                let debit = '';
                let vochertype = "";
                let vocher = "";

                if (invinfo.under == "sale") {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    vochertype = "sale";
                    credit = "";
                    debit = invinfo.total;
                    vocher = "Sale" + "-" + invinfo.reference_no;
                } else if (invinfo.under == "purchase") {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    vochertype = "purchase";
                    credit = invinfo.total;
                    debit = "";
                    vocher = "Purchase" + "-" + invinfo.reference_no;
                } else if (invinfo.under == 'payment_out') {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    vochertype = "payment_out";
                    credit = "";
                    debit = invinfo.total;
                    vocher = "Payment" + "-" + invinfo.reference_no;
                } else if (invinfo.under == 'creditnote') {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    vochertype = "credit_note";
                    credit = invinfo.total;
                    debit = "";
                    vocher = "Credit Note" + "-" + invinfo.reference_no;
                } else if (invinfo.under == 'debitnote') {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    vochertype = "debit_note";
                    credit = "";
                    debit = invinfo.total;
                    vocher = "Debit Note" + "-" + invinfo.reference_no;
                } else if (invinfo.under == "journal") {
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    let total = JSON.parse(invinfo.total);
                    let keys = Object.keys(total)
                    vochertype = "journal";
                    if (invinfo.ledger_cr == val.name && keys.includes(val.name)) {
                        credit = total[val.id] && total[val.id].credit;
                        debit = total[val.id] && total[val.id].debit;
                    } else {
                        credit = total[val.id] && total[val.id].credit;
                        debit = total[val.id] && total[val.id].debit;
                    }
                    vocher = "journal" + "-" + invinfo.reference_no;
                } else {
                    vochertype = "payment_in";
                    particul = invinfo.ledger_cr == val.name ? invinfo.ledger_dr : invinfo.ledger_cr;
                    credit = invinfo.total;
                    debit = "";
                    vocher = "Receipt" + "-" + invinfo.reference_no;
                }
                alldata.push({
                    id,
                    date: invinfo.date,
                    particulars: particul,
                    debit: debit,
                    credit: credit,
                    balance: 0,
                    vochertype,
                    val,
                    vocher: vocher,
                });
            });

            let result = alldata.sort((a, b) => {
                a = a.date && a.date.split('/').reverse().join('');
                b = b.date && b.date.split('/').reverse().join('');
                return a > b ? 1 : a < b ? -1 : 0;
            });

            // Calculate opening, closing balances and totals
            let runningBalance = 0;
            let totalCredit = 0;
            let totalDebit = 0;

            result.forEach((element, index) => {
                if (index === 0) {
                    element.balance = runningBalance;
                } else {
                    if (element.debit) {
                        runningBalance += parseFloat(element.debit);
                        totalDebit += parseFloat(element.debit);
                    }
                    if (element.credit) {
                        runningBalance -= parseFloat(element.credit);
                        totalCredit += parseFloat(element.credit);
                    }
                    element.balance = runningBalance;
                }
            });

            // Add closing balance object
            result.push({
                date: null,
                particulars: "Closing Balance",
                debit: totalDebit,
                credit: totalCredit,
                balance: runningBalance,
                vochertype: null,
                val: null,
                vocher: null,
            });

            // Setting the filter data with balances
            setFilterdata(result);
            // console.log(result);
            setAlldata(result);

        }

    }
    const viewdata = (bosno, vtype) => {
        // let path = '';
        // if (vtype == 'sale') {
        //     path = `/sale/${bosno}`
        // }
        // else if (vtype == 'purchase') {
        //     path = `/purchase/${bosno}`
        // } else if (vtype == 'credit_note') {
        //     path = `/credit-note/${bosno}`
        // } else if (vtype == 'debit_note') {
        //     path = `/debit-note/${bosno}`
        // } else if (vtype == 'payment_in') {
        //     path = `/payment-in/${bosno}`
        // } else if (vtype == 'payment_out') {
        //     path = `/payment-out/${bosno}`
        // } else if (vtype == 'journal') {
        //     path = `/journal/${bosno}`
        // }
        // else {
        //     path = '/dashboard'
        // }

        let path = '';
        if (vtype == 'sale') {
            path = {table:"sale",slug:bosno}
        }else if (vtype == 'purchase') {
            path = {table:"purchase",slug:bosno};
        } else if (vtype == 'credit_note') {
            path = {table:"creditnote",slug:bosno}
        } else if (vtype == 'debit_note') {
            path = {table:"debitnote",slug:bosno}
        } else if (vtype == 'payment_in') {
            path = {table:"payment-in",slug:bosno}
        } else if (vtype == 'payment_out') {
            path = {table:"payment-out",slug:bosno}
        } else if (vtype == 'journal') {
            path = {table:"journal",slug:bosno}
        }
        else {
            path = {table:"dashboard"}
        }
        updateinvoicecomponet(path,"ledger");
    }
    const viewdelete = async (id, vtype, val, slug) => {

        if (confirm("do you really delete this invoice")) {
            let table = "";
            if (vtype == "sale" || vtype == "purchase" || vtype == "credit_note" || vtype == "debit_note") {
                table = "s_p_cn_dn";
            } else if (vtype == "payment_in" || vtype == "payment_out") {
                table = "pi_po";
            } else if (vtype == "journal") {
                table = "journal";
            }
            let res = await axios.delete(`${url}/viewdata_delete/${auth.id}_${table}/${id}`);
            if (res.status == 200) {
                let res = await axios.delete(`${url}/viewstock_delete/${auth.id}_stock/${slug}`)
                if (res.status == 200) {
                    APICalling();
                    Invoicedatafilter(JSON.stringify(val))
                }

            }

        }

    }
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };
    useEffect(() => {
        filterData();
    }, [startDate, endDate]);
    const filterData = () => {
        let openingBalance = 0;
        filterdata.forEach(item => {
            if (item.date !== null) {
                const itemDate = new Date(item.date.split('/').reverse().join('-'));
                const rangeStartDate = startDate ? new Date(startDate) : null;
                if (rangeStartDate && itemDate < rangeStartDate) {
                    if (item.debit) {
                        openingBalance += parseFloat(item.debit);
                    }
                    if (item.credit) {
                        openingBalance -= parseFloat(item.credit);
                    }
                }
            }
        });

        // Filter data based on the provided date range
        const filtered = filterdata.filter(item => {
            if (item.date === null) return true; // Include the opening balance record
            const itemDate = new Date(item.date.split('/').reverse().join('-'));
            const rangeStartDate = startDate ? new Date(startDate) : null;
            const rangeEndDate = endDate ? new Date(endDate) : null;
            if (rangeStartDate && itemDate < rangeStartDate) {
                return false;
            }
            if (rangeEndDate && itemDate > rangeEndDate) {
                return false;
            }
            return true;
        });

        // Initialize the running balance with the calculated opening balance
        let runningBalance = openingBalance;
        let totalCredit = 0;
        let totalDebit = 0;

        // Create a new array to store the filtered data with balances
        const resultWithBalances = [
            {
                date: null,
                particulars: "Opening Balance",
                debit: null,
                credit: null,
                balance: runningBalance,
                vochertype: null,
                val: null,
                vocher: null,
            }
        ];

        filtered.forEach(item => {
            if (item.debit && item.date != null) {
                runningBalance += parseFloat(item.debit);
                totalDebit += parseFloat(item.debit);
            }
            if (item.credit && item.date != null) {
                runningBalance -= parseFloat(item.credit);
                totalCredit += parseFloat(item.credit);
            }
            if (item.date != null) {
                resultWithBalances.push({
                    ...item,
                    balance: runningBalance,
                });
            }

        });

        // Add closing balance object to the result array
        resultWithBalances.push({
            date: null,
            particulars: "Closing Balance",
            debit: totalDebit,
            credit: totalCredit,
            balance: runningBalance,
            vochertype: null,
            val: null,
            vocher: null,
        });

        setAlldata(resultWithBalances);
    }

    let debittotal = 0;
    let credittotal = 0;
    alldata && alldata.forEach((data) => {
        credittotal += data.credit != "" ? parseInt(data.credit) : 0;
        debittotal += data.debit != "" ? parseInt(data.debit) : 0;
    })
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    return (
        <div>
            {/* <GlobalKeyListener/> */}
            <div className='row'>
                <div className='col-md-12'>
                    <div className='text-center fs-1 text-decoration-underline'>
                        {ledgerinfo.name}
                    </div>
                    {/* <div className='text-center'>

                        <select onChange={(e) => Invoicedatafilter(e.target.value)} className='border-1 '>
                            <option value={JSON.stringify({ id: '', name: '' })}>--Select--</option>
                            {
                                ledgerdata && ledgerdata.map((obj) =>
                                    <option value={JSON.stringify({ id: obj.id, name: obj.name })}>{obj.name}</option>
                                )
                            }
                        </select>
                        A/C
                    </div> */}
                    <div className='text-center'>
                        <input type='date' value={startDate} onChange={handleStartDateChange}  ></input>
                        <label>to</label>
                        <input type='date' value={endDate} onChange={handleEndDateChange} ></input>
                    </div>
                    <div class="table-responsive-md">
                        {/* <div className='col text-end mb-3'>
                            <span className='fw-bold'>Opening Balance:&#8377;<span className='text-dark'>{openingblance}</span></span>
                        </div> */}
                        <table className='table border'>
                            <thead class="table-light">
                                <tr>
                                    <th>Date</th>
                                    <th>Particulars</th>
                                    <th>Voucher</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    <th>Balance</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    alldata && alldata.map((info) =>
                                        <tr>
                                            <th>{info.date && info.date}</th>
                                            <th>{info.particulars && info.particulars[0].toUpperCase() + info.particulars.slice(1)}</th>
                                            <th>{info.vocher && info.vocher[0].toUpperCase() + info.vocher.slice(1)}</th>
                                            <th>{info.debit && info.debit != "" ? `₹${info.debit}` : null}</th>
                                            <th>{info.credit && info.credit != "" ? `₹${info.credit}` : null}</th>
                                            <th>&#8377;{info.balance && info.balance}</th>
                                            {
                                                info.date != null ? <th>
                                                    <button className='mx-1 btn btn-primary' onClick={() => viewdata(info.vocher.toLowerCase().replace(/\s/g, '').trim(), info.vochertype)} ><i class="bi bi-pen"></i></button>
                                                    <button className='mx-1 btn btn-danger' onClick={() => viewdelete(info.id, info.vochertype, info.val, info.vocher.toLowerCase().replace(/\s/g, '').trim())}><i class="bi bi-trash"></i></button>
                                                </th> : null
                                            }

                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                        {/* <div className='col text-end'>
                            <span className='fw-bold'>Closing Balance:&#8377;{Math.sign(closeblance) == 1 ? <span className='text-success'>{closeblance}</span> : <span className='text-danger'>{closeblance}</span>}</span>
                        </div> */}
                    </div>
                    <div className='text-center'>
                        <button onClick={printbill} className='btn btn-primary' id="printfun"><i class="bi bi-printer"></i> Print</button>
                        &nbsp;<button onClick={()=>update_and_back("")} className='btn btn-dark' ><i class="bi bi-arrow-left-short"></i>Back</button>
                    </div>
                </div>
            </div>
            <GlobalKeyListener onPrint={printbill} />
            <div className='row d-none' >
                <div className='col-12' >
                    <div className='card' ref={printref}>
                        <div className='card-body'>
                            <div className='display-5 text-center'>
                                {auth.frm_name}
                            </div>
                            <div className='text-center'>
                                {auth.address}
                            </div>
                            {/* <div className='text-center'>
                                {ledgername.charAt(0).toUpperCase() + ledgername.slice(1)} A/C
                            </div> */}
                            <div className='text-center'>
                                {ledgername && ledgername.charAt(0).toUpperCase() + ledgername.slice(1)} A/C
                            </div>
                            <div className='text-center'>
                                {startDate && endDate ? `${startDate} to ${endDate}` : null}
                            </div>
                            <div class="table-responsive-md">
                                {/* <div className='col text-end mb-3'>
                                    <span className='fw-bold'>Opening Balance:<span className='text-dark'>{openingblance}</span></span>
                                </div> */}
                                <table className='table'>
                                    <thead class="table">
                                        <tr>
                                            <th>Date</th>
                                            <th>Particulars</th>
                                            <th>Voucher</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            alldata && alldata.map((info) =>
                                                <tr>
                                                    <th>{info.date && info.date}</th>
                                                    <th>{info.particulars && info.particulars[0].toUpperCase() + info.particulars.slice(1)}</th>
                                                    <th>{info.vocher && info.vocher[0].toUpperCase() + info.vocher.slice(1)}</th>
                                                    <th>{info.debit && info.debit != "" ? info.debit : null}</th>
                                                    <th>{info.credit && info.credit != "" ? info.credit : null}</th>
                                                    <th>{info.balance && info.balance}</th>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                {/* <div className='col text-end'>
                                    <span className='fw-bold'>Closing Balance:{Math.sign(closeblance) == 1 ? <span className='text-success'>{closeblance}</span> : <span className='text-danger'>{closeblance}</span>}</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Ledger