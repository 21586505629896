import axios from 'axios';
import React, { useState } from 'react'
import { ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import ChangePassword from './ChangePassword';
function OtpVerify({ url, email, ChangeNavRed }) {
    const [otp, setOtp] = useState('');
    const redirect = useNavigate();
    const [changesComponet, setChangesComponet] = useState("otp-verify");
    const Otpcheck = async () => {
        const res = await axios.post(`${url}/otp-verify`, { otp: otp, email: email })
        console.log(res.data);
        if (res.data == "Valid") {
            setChangesComponet('change-new-password');
            // redirect(`/forgot-password/${email}`);
        } else if (res.data == "Invalid") {
            alert("invalid otp")
            toast.error('Invalid OTP');
        }
    }
    return (
        <div>
            {
                changesComponet == "otp-verify" ? <div className="container mt-5 d-flex justify-content-center vh-100">
                    <div className="card p-4" style={{ maxWidth: '400px', width: '100%' }}>
                        <div className='text-center' >
                            <i class="bi bi-shield-lock-fill"></i>
                        </div>
                        <h5 className="text-center mb-4">Enter Your OTP</h5>
                        <div className="d-flex justify-content-between">

                            <input
                                type="text"
                                className="form-control text-center mx-1"
                                maxLength="6"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-primary mt-4 w-100" onClick={() => Otpcheck()}>
                            Submit
                        </button>
                    </div>
                </div>:changesComponet=="change-new-password"?<ChangePassword url={url} mail={email} ChangeNavRed={ChangeNavRed}/>:null
            }

            <ToastContainer />
        </div>
    )
}

export default OtpVerify
