import React from 'react'
import { useUser } from '../../Authdata';
function PaymentIN_invoice_one({url,recNo,date,ledger,amount,wordsTotal}) {
    const {auth} = useUser();
    return (
        <div>
            <div class="card-body mx-4">
                <div class="container text-center">
                    <img src={`${url}/upload/${auth && auth.logo}`} height={"80px"}></img>
                    <p class="text-center fw-bold" style={{ fontSize: "30px" }}>{auth.frm_name}</p>
                    <p class="text-center mb-0" >{auth.address}</p>
                    <span class={`text-center mt-0`} >{auth.mobile} || {auth.email}</span>
                    <h5 class="text-center mt-2" >Receipt Voucher</h5>
                    <div class="row">
                        <table >
                            <tr className=''>
                                {/* <th>BOS No:{bosNo}</th> */}
                                <th>No:{recNo}</th>
                                <th className='text-end'>Date:{date}</th>
                            </tr>
                            <tr>
                                {/* <td><b>{debtarname}</b></td> */}
                            </tr>
                        </table>
                        <hr></hr>
                        <table className='table '>
                            <tr>
                                <th >Particulars</th>
                                {/* <th >Qty</th>
                                                        <th >Rate</th> */}
                                <th >Amount</th>
                            </tr>
                            <tr>
                                <td><b>Account:</b>{ledger}</td>
                                <td>&#8377;{amount}</td>
                            </tr>

                            {/* {
                                                    billing.map((item) =>
                                                        <tr>
                                                            <td >{item.particular}</td>
                                                            <td >{item.quaty}</td>
                                                            <td >{item.rate}</td>
                                                            <td >{item.Amout}</td>
                                                        </tr>
                                                    )
                                                } */}
                        </table>
                        <hr style={{ border: "2px solid black" }}></hr>

                        {/* <table className='table'>
                                                    <tr>
                                                        <td>{leadger}</td>
                                                        <td>{amount}</td>
                                                    </tr>
                                                </table> */}

                    </div>


                    <div className='row'>
                        <table>
                            <tr >
                                <th class="float-start fw-normal">Total Amount in Words:&nbsp;<span className='fw-bold'>{wordsTotal}</span></th>
                                <th class="float-end fw-bold ">Total:₹{amount}</th>
                            </tr>
                        </table>
                        <hr style={{ border: "2px solid black" }}></hr>
                    </div>
                    {/* <div className='row'>
                                            <div className='col'>FSSAI NO:</div>
                                            <div className='col'>{auth.fssai}</div>
                                        </div>
                                        <div class="text-center" style={{ marginTop: "20px" }}>
                                            <u class="text-dark">Thank You Visit Again </u>
                                            <p >Please Check The Bill Before Making The Payment
                                                No Complaints Will Be Entertain Thereafter
                                            </p>
                                            <p class="text-start">SRI GANESHAY NAMAH</p>
                                        </div> */}

                </div>
            </div>
        </div>
    )
}

export default PaymentIN_invoice_one