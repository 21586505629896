import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToWords } from 'to-words';
import LedgerPop from '../LedgerPop';
import Invoiceaddpanel from '../Invoiceaddpanel';
import GlobalKeyListener from '../../GlobalKeyListener';
import Purchasae_invoice_one from './Purchasae_invoice_one';
import { useUser } from '../../Authdata';
import Purchase_invoice_three from './Purchase_invoice_three';
import './purchaseinvoice.css';
function Purchase({url, updateinfo, settingcontrol, update_and_back, backComponet}) {
    const { auth } = useUser();
    const [reloadpage, setReloadpage] = useState(0);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const printref = useRef();
    const [data, setData] = useState([]);
    const [bosNo, setBosNo] = useState(0);
    const [billing, setBilling] = useState([]);
    const [BillControler, setBillControler] = useState("cash");
    const [debtarname, SetDebtarname] = useState({});
    const redirect = useNavigate();
    const [datelocal, setDatelocal] = useState("");
    const [confirmsave, setConfirmSave] = useState(false);
    const toWords = new ToWords();
    const params = updateinfo;
    const [vocherdata, setVocherData] = useState(null);
    const [newbill, setNewBill] = useState(false)
    const [purchase, setPurchase] = useState("");
    const [alldataIndireact_direact, setAlldataIndireact_direact] = useState([]);
    const [deletebillItem, setDeleteBillItem] = useState(null);
    const [ledgerdata, setLedgerData] = useState([]);
    const [editbillItem, setEditBillItem] = useState(null);
    var date = "";
    useEffect(() => {
        setSetting(JSON.parse(localStorage.getItem('setting')))
    }, [settingcontrol]);
    let AddBilledata = (objs) => {
        // console.log(objs);
        // let billdata = [...billing, objs];
        setBilling(objs);
        setNewBill(false)
        // setBilling(objs);
    }
    useEffect(() => {
        const callLedgerdata = async () => {
            let res = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
            // console.log(res.data);
            setLedgerData(res.data);
        }
        callLedgerdata();
    }, [])
    let localdateset = (d) => {
        setDatelocal(d);
    }
    const newBill = () => {
        setNewBill(true)
        setConfirmSave(false)
        setAlldataIndireact_direact([]);
    }
    const debitornamesetup = (name) => {
        if (name) {
            let obj = JSON.parse(name);
            SetDebtarname(obj && obj);
        }
    }
    let total = 0;
    let totalamount = 0;
    billing.forEach((da) => {
        total += da.Amout;
    })
    let discount = 0;
    
    alldataIndireact_direact.forEach((v) => {
        
        if (v.Ismode === "positive") {
            if (v.percentage != "") {
                discount = total * (v.percentage / 100)
                totalamount = totalamount + Math.round(discount)

            } else {
                totalamount = totalamount + Number(v.amount)
            }
        }
        else {
            if (v.percentage != "") {
                discount = total * (v.percentage / 100)
                totalamount = totalamount - Math.round(discount)

            } else {
                totalamount = totalamount - Number(v.amount)
            }
        }

    })
    let amouttotal = totalamount !== 0 ? totalamount : total;
    var wordstotal = toWords.convert(amouttotal, { currency: amouttotal % 1 !== 0 });
    wordstotal = amouttotal % 1 !== 0 ?  wordstotal :`Rupess ${wordstotal}` ;
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    async function SaveBilldata() {
        if (billing.length == 0) {
            toast.info("bill is empty")
        }
        else {

            let bill = JSON.stringify(billing);
            let indireact_and_direact = JSON.stringify(alldataIndireact_direact);
            let ledger_cr = "";
            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_cr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_cr = cashLedger.id;
                }
            }
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill, indireact_and_direact }),
                total: totalamount != 0 ? totalamount : total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_cr,
                slug: `purchase-${bosNo}`,
                ledger_dr: purchase.id,
                under: "purchase"
            }
            const purchaseResponse = await axios.post(`${url}/insert_purchase/${auth.id}_s_p_cn_dn`, data);
            setConfirmSave(true);
            if (purchaseResponse.data) {
                billing.forEach((bill) => {
                    let billdata = {
                        date: datelocal,
                        particulars: bill.pro_id,
                        rate: bill.rate,
                        quantity: bill.quaty,
                        unit: bill.unit,
                        total: bill.Amout,
                        frm_id: auth.id,
                        bill_id: `purchase-${bosNo}`,
                        voucher: "purchase"
                    }
                    // console.log(billdata);
                    axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata)

                })
                
                toast.success("save");
            }
        }
    }
    let UpdateBilldata = async () => {
        try {
            if (!Array.isArray(billing) || billing.length === 0) {
                toast.error("Voucher is Empty");
                return;
            }
            let bill = JSON.stringify(billing);
            let indireact_and_direact = JSON.stringify(alldataIndireact_direact);
            let ledger_cr = "";

            if (debtarname && debtarname.name && BillControler === "debtor") {
                ledger_cr = debtarname.id;
            } else {
                let cashLedger = ledgerdata.find((ledger) => ledger.name === "Cash");
                if (cashLedger) {
                    ledger_cr = cashLedger.id;
                }
            }
            let data = {
                date: datelocal,
                bills: JSON.stringify({ bill, indireact_and_direact }),
                total: totalamount != 0 ? totalamount : total,
                frm_id: auth.id,
                bos_no: bosNo,
                ledger_cr: ledger_cr,
                ledger_dr: purchase.id,
                slug: `purchase-${bosNo}`,
                under: "purchase"
            };

            let res = await axios.put(`${url}/viewdataupdate/${auth.id}_s_p_cn_dn/purchase/${params.slug}`, data);

            if (res.data) {
                // Collect promises for batch processing
                const promises = billing.map(async (item) => {
                    if (!('stockid' in item)) {
                        let billdata = {
                            date: datelocal,
                            particulars: item.pro_id,
                            rate: item.rate,
                            quantity: item.quaty,
                            unit: item.unit,
                            total: item.Amout,
                            frm_id: auth.id,
                            bill_id: `purchase-${bosNo}`,
                            voucher: "purchase"
                        };
                        console.log(billdata);
                        await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);
                    }
                });
                await Promise.all(promises);
                // Redirect after all operations are done
                update_and_back(backComponet);
                // redirect('/dashboard');
            }
        } catch (error) {
            console.error("Error updating bill data:", error);
            // You can add further error handling here if needed, like showing an alert to the user
        }
    }
    const billLedgerSaleandPurchaseset = (val) => {
        setPurchase(val)
    }
    const UpdateVoucher = (objs) => {
        setVocherData(objs);
    }
    const Indireact_and_direact = (v) => {
        console.log("Purchase",v);
        setAlldataIndireact_direact(v);
    }
    const billcontroler = (val) => {
        setBillControler(val)
    }
    const billofsplly = (val) => {
        // console.log(val);
        // if(setting.bosno=="manual"){
        setBosNo(val.bosno);
        // alert(val)
        // }
    }
    const CrandDr = (val) => {
        if (val) {
            let obj = JSON.parse(val);
            setPurchase(obj)
        }
    }
    const editItem = (val,nf) => {
        if(nf=="editstart"){
            setEditBillItem(val);
        }else{
            setEditBillItem(null);
        }
    }
    const deleteItem = (val) => {
        setDeleteBillItem(val);
    }
    return (
        <div>
            <GlobalKeyListener onSave={SaveBilldata} onPrint={printbill} onNew={newBill} />
            <div>
            </div>
            <div className='row text-center ' >
                <div className='col-sm-2 border'>
                    <Invoiceaddpanel adbilldata={AddBilledata} url={url} settingcontrol={settingcontrol} localdate={localdateset} vocheri={"purchase"}  billNo={billofsplly} newbill={newbill} debitornamesetup={debitornamesetup} voucherupdate={UpdateVoucher} Indireact_and_direact={Indireact_and_direact} billcontroler={billcontroler} setupledcranddr={CrandDr} returnBillno={null} deletebillItem={deletebillItem} deleteItem={deleteItem} editbillItem={editbillItem} editItem={editItem} updateinfo={updateinfo}/>

                </div>
                <div className={setting.invoice == "invoice-1" ? 'col-10 d-flex justify-content-center mt-5 mb-5' : "col-md-10"} >
                    <div >
                        <div class="text-center me-5 mx-5" ref={printref} >
                            {setting.invoice == "invoice-1" ?
                                <Purchasae_invoice_one url={url} bosNo={bosNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} editItem={editItem} BillControler={BillControler} invoiceAlignmentmeng={setting.invoiceAlignment} />
                                : <Purchase_invoice_three url={url} bosNo={bosNo} datelocal={datelocal} billing={billing} alldataIndireact_direact={alldataIndireact_direact} discount={discount} total={total} wordstotal={wordstotal} debtarname={debtarname} totalamount={totalamount} deleteIndex={deleteItem} editItem={editItem} BillControler={BillControler} invoicdesc={setting.invoicedescription} invoiceAlignmentmeng={setting.invoiceAlignment} />
                            }
                            <div className="invoice-footer">
                                <p>This is a computer-generated invoice and does not require a signature.</p>
                            </div>
                        </div>
                        {
                            vocherdata ? <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid">
                                    <button onClick={UpdateBilldata} className='btn btn-success' ><i class="bi bi-arrow-repeat"></i>Update</button>
                                    <button onClick={()=>update_and_back(backComponet)} className='btn btn-dark'><i class="bi bi-arrow-left-short"></i>Back</button>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" ><i class="bi bi-printer"></i>Print</button>
                                </div>
                            </nav> : <nav class="navbar navbar-expand-lg navbar-light bg-light border me-5 mx-5">
                                <div class="container-fluid">

                                    <button onClick={SaveBilldata} className='btn btn-primary' disabled={confirmsave ? true : false}><i class="bi bi-floppy-fill" ></i> Save</button>
                                    <button onClick={printbill} className='btn btn-primary' id="printfun" disabled={confirmsave ? false : true}><i class="bi bi-printer"></i> Print</button>
                                    <button className='btn btn-dark' onClick={() => newBill()} disabled={confirmsave ? false : true}><i class="bi bi-file-earmark-plus"></i> New</button>
                                </div>
                            </nav>
                        }



                    </div>
                    {/* <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <LedgerPop url={url} onChildStateChange={LedgerData} />
                    </div> */}
                </div>
                <ToastContainer />
            </div>
        </div >
    )
}

export default Purchase