import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import GlobalKeyListener from '../GlobalKeyListener';
import { useUser } from '../Authdata';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import RegisterPrint_invoice from './RegisterPrint_invoice';
function ReceiptRegister({url, updateinvoicecomponet}) {
    const printref = useRef();
    const { auth } = useUser();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [receiptdata, setReceiptData] = useState([]);
    const [filterdata, setFilterdata] = useState([]);
    const [total,setTotal]=useState(0);
    const printbill = useReactToPrint({
        content: () => printref.current
    })
    const fetchData=async()=>{
        // axios.get(`${url}/payment-in/${auth.id}_pi_po`).then((res) => {
        //     let result = res.data.sort(function (a, b) {
        //         a = a.date.split('/').reverse().join('');
        //         b = b.date.split('/').reverse().join('');
        //         return a > b ? 1 : a < b ? -1 : 0;
        //     });
        //     setReceiptData(result);
        //     setFilterdata(result)
        // })
        try {
            // Fetch purchase data
            const reciptResponse = await axios.get(`${url}/payment-in/${auth.id}_pi_po`);
            let sortedRecipteData = reciptResponse.data;
            const updatedReciptDataPromies = sortedRecipteData.map(async (reciptItem) => {
                try {
                    const ledgerResponseCr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${reciptItem.ledger_cr}`);
                    const ledgerDataCr = ledgerResponseCr.data;
                    const ledgerResponseDr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${reciptItem.ledger_dr}`);
                    const ledgerDataDr = ledgerResponseDr.data;
                    return {
                        ...reciptItem,
                        ledger_cr: ledgerDataCr.name,
                        ledger_dr: ledgerDataDr.name
                    };
                } catch (ledgerError) {
                    console.error(`Failed to fetch ledger data for ledger_dr ${reciptItem.ledger_cr}:`, ledgerError);
                    return reciptItem; // Return the original item if the ledger request fails
                }
            });
            sortedRecipteData = await Promise.all(updatedReciptDataPromies);
            setReceiptData(sortedRecipteData);
            setFilterdata(sortedRecipteData)

          
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    } 
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        filterData();
    }, [startDate, endDate]); // Re-run filterData whenever startDate or endDate changes

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filterData = () => {
        const filtered = filterdata.filter(item => {
            const itemDate = new Date(item.date.split('/').reverse().join('-'));
            const rangeStartDate = startDate ? new Date(startDate) : null;
            const rangeEndDate = endDate ? new Date(endDate) : null;

            if (rangeStartDate && itemDate < rangeStartDate) {
                return false;
            }

            if (rangeEndDate && itemDate > rangeEndDate) {
                return false;
            }

            return true;
        });

        setReceiptData(filtered); // Update filteredData state, not purchaseData
    };
    useEffect(()=>{
        let total=0;
        receiptdata.forEach((data)=>{
            total+=parseInt(data.total);
        })
        setTotal(total);
    },[receiptdata])
    const deleteregister = async(id, vtype, val,slug) => {

        if (confirm("do you really delete this invoice")) {
            let table="";
            if(vtype=="payment_in" || vtype=="payment_out"){
                table="pi_po";
            }
            let res=await axios.delete(`${url}/viewdata_delete/${auth.id}_${table}/${id}`);
            if (res.status == 200) {
                let res=await axios.delete(`${url}/viewstock_delete/${auth.id}_stock/${slug}`)
                if(res.status==200){
                    fetchData();
                }
            }
        }
    }
    return (
        <div>
            <GlobalKeyListener />
            <div>
                <div className='container'>
                    <div className='row mb-4 '>
                        <div className='col-md-6'>
                            <h1 className='text-decoration-underline'>List Of All Receipt Voucher</h1>
                        </div>
                        <div className='col-md-6 mt-3'>
                            <input type='date' value={startDate} onChange={handleStartDateChange}  ></input>
                            <label>to</label>
                            <input type='date' value={endDate} onChange={handleEndDateChange} ></input>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Particulars</th>
                                        <th scope="col">Rec No</th>
                                        <th scope="col">Credit</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        receiptdata && receiptdata.map((data) =>
                                            <tr>
                                                <th scope="row">{data.date}</th>
                                                <td>{data.ledger_cr}</td>
                                                <td>{data.rec_no}</td>
                                                <td>{data.total}</td>
                                                <td>
                                                    <Link className='btn btn-primary' onClick={()=>updateinvoicecomponet({table:data.under.replace("_","-"),slug:data.slug},"register")}><i className='bi bi-pen'></i></Link>&nbsp;
                                                    <Link className='btn btn-danger' onClick={()=>deleteregister(data.id,"payment_in",data,data.slug)}><i className='bi bi-trash'></i></Link>&nbsp;
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td className='fw-bold'>&#8377;{total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='row'>
                    <div className='text-center mt-2 '>
                        <button className='btn btn-primary' onClick={printbill} id="printfun"><i class="bi bi-printer"></i> Print</button>
                    </div>
                </div>
                </div>
                <div className='row d-none' >
                <div className='text-center' ref={printref}>
                    <RegisterPrint_invoice saledata={receiptdata} total={total} register={"Receipt"} filterdate={startDate && endDate ?`${startDate} to ${endDate}`:null}/>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ReceiptRegister