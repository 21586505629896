import React, { useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const GlobalKeyListener = ({ onSave ,onPrint,onNew,children ,onledger,onDeleteInvoice}) => {
  const navigate = useNavigate();
  const focusableElements = useRef([]);
  const initialIndex = useRef(0);
  // Add event listener for keydown events
  useEffect(() => {
    focusableElements.current = Array.from(
        document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      );
  
      // Find the initial focused element
      initialIndex.current = focusableElements.current.findIndex(el => el === document.activeElement);
    const handleKeyDown = (event) => {
      // Check if the Escape key is pressed
      if (event.key === 'Escape') {
        // Navigate back to the previous page
        navigate(-1); // Navigate back one step
      }
      if (event.ctrlKey && event.key === 's') {
        // Call the onSave function (passed as prop) to handle saving
        if (typeof onSave === 'function') {
          onSave();
        }

        // Prevent the default behavior of Ctrl + S (usually save page)
        event.preventDefault();
      }
      if (event.ctrlKey && event.key === 'p') {
        // Call the onSave function (passed as prop) to handle saving
        if (typeof onPrint === 'function') {
          onPrint();
        }

        // Prevent the default behavior of Ctrl + S (usually save page)
        event.preventDefault();
      }
      if (event.altKey && event.key === 'n') {
        // Call the onSave function (passed as prop) to handle saving
        if (typeof onNew === 'function') {
          onNew();
        }

        // Prevent the default behavior of Ctrl + S (usually save page)
        event.preventDefault();
      }
      if (event.ctrlKey && event.key === 'm') {
        // Call the onSave function (passed as prop) to handle saving
        if (typeof onledger === 'function') {
          onledger();
        }

        // Prevent the default behavior of Ctrl + S (usually save page)
        event.preventDefault();
      }
      if (event.altKey && event.key === 'd') {
        // Call the onSave function (passed as prop) to handle saving
        if (typeof onledger === 'function') {
          onDeleteInvoice();
        }

        // Prevent the default behavior of Ctrl + S (usually save page)
        event.preventDefault();
      }
      const currentIndex = focusableElements.current.findIndex(el => el === document.activeElement);

    //   if (event.key === 'Enter') {
    //     // Move focus to the next element (like tab behavior)
    //     const nextIndex = currentIndex === focusableElements.current.length - 1 ? 0 : currentIndex + 1;
    //     focusableElements.current[nextIndex].focus();
    //     event.preventDefault(); // Prevent default behavior (e.g., form submission)
    //   } 
    //   if (event.key === 'Escape') {
    //     // Move focus to the previous element (like Shift + Tab behavior)
    //     const prevIndex = currentIndex === 0 ? focusableElements.current.length - 1 : currentIndex - 1;
    //     focusableElements.current[prevIndex].focus();
    //     event.preventDefault(); // Prevent default behavior (e.g., closing modal)
    //   }
    };

    // Attach event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate,onSave]); // Include navigate in the dependencies array to ensure it's updated

  // This component doesn't render anything visible in the UI
  return null;
};

export default GlobalKeyListener;
