import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../Authdata';
import axios from 'axios';
function Setting({ settingStateChange }) {
    const setting = JSON.parse(localStorage.getItem("setting"));
    const { auth, Authclear, reloadpage } = useUser();
    const [setting_bosno, setSetting_Bosno] = useState(auth && auth.bosno ? auth.bosno : "automatic");
    const [invoicechanger, setInvoiceChanger] = useState(auth && auth.invoice ? auth.invoice : "invoice-1");
    const [invoicedescription, setInvoiceDescription] = useState(auth && auth.invoicedescription ? auth.invoicedescription : "");
    const [alignMentMangment, setAlignMentMangment] = useState({
        logo: "",
        firm_name: "",
        address: "",
        show_logo: "",
        show_firm_name: "",
        show_address: "",
        show_desc: ""

    })
    const [settingNav, setSettingnav] = useState("functions");

    //this function use all alignment setting invoice templates mangement
    const alignmentMangmentHandal = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAlignMentMangment((prev) => {
            return { ...prev, [name]: value }
        })
    }
    //settings template active show code
    var elementSelected = null;
    var typeSelected = false;

    $(document).on('click', '.list-image > img', function () {
        $('.list-image > img').each(function () {
            $(this).removeClass('active');
        })
        $(this).addClass('active');
        elementSelected = $(this);
        typeSelected = false;
    });
    const SettingSetup = () => {
        localStorage.setItem("setting", JSON.stringify({
            bosno: setting_bosno,
            invoice: invoicechanger,
            invoicedescription: invoicedescription,
            invoiceAlignment: alignMentMangment
        }));
        settingStateChange();

    }
   

   
    return (
        <>
            <button className="nav-link d-md-block d-none" data-bs-toggle="modal" data-bs-target="#setting"><i className="bi bi-gear">&nbsp;</i>Settings</button>
            <button className="nav-link d-block d-md-none" data-bs-toggle="modal" data-bs-target="#setting"><i className="bi bi-gear">&nbsp;</i></button>
            <div class="modal fade" id="setting" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row '>
                                <div className='col-md-4 border-end'>
                                    <table class="table">

                                        <tbody>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("functions")} className='active'>Functions</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("invoice-template")}>Invoice Template</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("invoice-print-configuration")}>Invoice Print Configuration</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link style={{ textDecoration: "none" }} onClick={() => setSettingnav("short-cut-keys")}>ShortCut List</Link></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-md-8'>
                                    {
                                        settingNav == "functions" ? <div className='row'>
                                            <div className='col-md-12'>
                                                <div class="mb-4 row">
                                                    <label class="col-sm-4 col-form-label">Bill Of Supply</label>
                                                    <div class="col-sm-8">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="bosno" id="Automatic" checked={setting_bosno == "automatic" ? true : false} value={"automatic"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                                            <label class="form-check-label" for="Automatic">
                                                                Automatic
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="bosno" id="Manual" checked={setting_bosno == "manual" ? true : false} value={"manual"} onChange={(e) => setSetting_Bosno(e.target.value)}></input>
                                                            <label class="form-check-label" for="Manual">
                                                                Manual
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-4 row '>

                                                    <div className='col-12 mt-1'>
                                                        <label>Invoice Description</label>
                                                        <textarea className='form-control' onChange={(e) => setInvoiceDescription(e.target.value)} >{invoicedescription}</textarea>
                                                    </div>



                                                </div>
                                            </div>
                                        </div> : settingNav == "invoice-template" ? <div className='col-md-12'>
                                            <div class="mb-4 row">
                                                <div class="select-image">
                                                    <div class="list-image">
                                                        <img className={`${invoicechanger == "invoice-1" ? "active" : ""}`} src="https://rupela.in/invoice1.jpg" onClick={(e) => setInvoiceChanger("invoice-1")} alt="invoice-1" height={"150px"} width={"200px"} />
                                                        <img className={`${invoicechanger == "invoice-2" ? "active" : ""}`} src="https://rupela.in/invoice2.png" alt="invoice-2" onClick={(e) => setInvoiceChanger("invoice-2")} height={"150px"} width={"200px"} />
                                                    </div>

                                                </div>

                                            </div>
                                        </div> : settingNav == "short-cut-keys" ? <div className='col-md-12'>
                                            <div className='row mb-4'>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ShortCut Key</th>
                                                            <th scope="col">Desciption of shortcut key</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Esc</th>
                                                            <td>Back to page</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Ctrl+S</th>
                                                            <td>Save to page</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Ctrl+P</th>
                                                            <td>Print Invoice</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Alt+N</th>
                                                            <td>New Invoice</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : <div>
                                           
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Logo</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="logo" id="logo_left" value={"start"} checked={alignMentMangment.logo == "start" ? true : false} onChange={alignmentMangmentHandal} ></input>
                                                        <label class="form-check-label" for="logo_left">
                                                            Left
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="logo" id="logo_center" value={"center"} checked={alignMentMangment.logo == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="logo_center">
                                                            Center
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="logo" id="logo_right" value={"end"} checked={alignMentMangment.logo == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="logo_right">
                                                            Right
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Firm Name</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="firm_name" id="firm_name_left" value={"start"} checked={alignMentMangment.firm_name == "start" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="firm_name_left">
                                                            Left
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="firm_name" id="firm_name_center" value={"center"} checked={alignMentMangment.firm_name == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="firm_name_center">
                                                            Center
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="firm_name" id="firm_name_right" value={"end"} checked={alignMentMangment.firm_name == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="firm_name_right">
                                                            Right
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Address</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="address" id="address_left" value={"start"} checked={alignMentMangment.address == "start" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="address_left">
                                                            Left
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="address" id="address_center" value={"center"} checked={alignMentMangment.address == "center" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="address_center">
                                                            Center
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="address" id="address_right" value={"end"} checked={alignMentMangment.address == "end" ? true : false} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="address_right">
                                                            Right
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Show Desciption</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_desc" id="show_desc_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_desc_show">
                                                            Show
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_desc" id="show_desc_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_desc_hide">
                                                            hide
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Show Logo</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_logo" id="show_logo_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_logo_show">
                                                            Show
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_logo" id="show_logo_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_logo_hide">
                                                            hide
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Show Firm Name</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_firm_name" id="show_firm_name_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_firm_name_show">
                                                            Show
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_firm_name" id="show_firm_name_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_firm_name_hide">
                                                            hide
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <label class="col-sm-4 col-form-label">Show Adress</label>
                                                <div class="col-sm-8">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_address" id="show_address_show" value={"block"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_address_show">
                                                            Show
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="show_address" id="show_address_hide" value={"none"} onChange={alignmentMangmentHandal}></input>
                                                        <label class="form-check-label" for="show_address_hide">
                                                            hide
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }


                                </div>

                            </div>



                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={SettingSetup}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Setting