import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../Authdata';
import LedgerPop from './LedgerPop';
import Invoiceaddpanel from './Invoiceaddpanel';
function Journal({ url, updateinfo, settingcontrol, update_and_back, backComponet }) {
    const { auth } = useUser();
    const [vocherdata, setVocherData] = useState([]);
    const [prodata, setProdata] = useState([]);
    const [ledger, setLedger] = useState([]);
    const [journal, setJournal] = useState([]);
    const [bos_no, setBosNo] = useState(0);
    const [datelocal, setDatelocal] = useState("");
    const [billing, setBilling] = useState([]);
    const [particular, setParticular] = useState("");
    const [qty, setQty] = useState([]);
    const [quaty, setQuaty] = useState(0);
    const [rate, setRate] = useState(0);
    const [Amout, setAmoutes] = useState(0);
    const [debtarname, setDebtarname] = useState(null);
    const [selectInputControl, setSelectInputControl] = useState("debtor");
    const [cranddr, setCrandDr] = useState({ state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { credit: 0, debit: 0 } });
    const redirect = useNavigate();
    const [nextfiled, setNextFiled] = useState([{ state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { credit: 0, debit: 0 } }]);
    const [pandsid, setPandSid] = useState(0);
    const [datasaveconfirm, setDataSaveConfirm] = useState(true);
    const [endtotal, setEndTotal] = useState([]);
    const [stock, setStock] = useState([]);
    const [unit, setUnit] = useState("");
    const params = updateinfo;
    useEffect(() => {
        const fetchData = async () => {
            if (params && params.slug) {
                try {
                    const res = await axios.get(`${url}/viewdata/${params.slug}/${auth.id}_journal/${auth.id}_stock/journal`);
                    if (res && res.data) {
                        const bill = JSON.parse(res.data.bills);
                        setVocherData(res.data);
                        setNextFiled(bill);
                        setBosNo(res.data.bos_no);
                        const [day, month, year] = res.data.date.split('/');
                        setDatelocal(`${year}-${month}-${day}`);
                    }
                } catch (error) {
                    console.error('Error fetching viewdata:', error);
                }
            } else {
                redirect("/dashboard");
            }

            try {
                const res = await axios.get(`${url}/show_products/${auth.id}_product/${auth.id}_stock`);
                setProdata(res.data);
            } catch (error) {
                console.error('Error fetching allpro data:', error);
            }

            try {
                const res = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
                const result = res.data.filter((val) => {
                    if (val.under == "sale" || val.under == "purchase" || val.under == "debtor" || val.under == "cash" || val.under == "creditor" || val.under == "bank") {
                        return val;
                    }
                });
                setLedger(result.sort(function (a, b) {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }));
            } catch (error) {
                console.error('Error fetching ledger data:', error);
            }
            try {
                await JournalGet();
            } catch (error) {
                console.error('Error fetching journal data:', error);
            }
        };
        fetchData();
    }, [params.slug, nextfiled, url]);
    const JournalGet = () => {
        if (vocherdata.length == 0) {
            axios.get(`${url}/journal/${auth.id}_journal`).then((res) => {
                let bosnoset = 0;
                res.data.forEach((obj) =>
                    bosnoset = obj.bos_no
                )
                setBosNo(parseInt(bosnoset) + 1)
            })
        }
    }
    const SetParRate = (v) => {
        setParticular(v)
        let val = JSON.parse(v);
        prodata && prodata.forEach((el) => {
            if (el.name == val.name) {
                setRate(el.sale_price);
                setUnit(el.unit);
            }
        })
    }
    const SetRateItem = (v) => {
        setRate(v ? v : 0);
        let amout = v * quaty;
        setAmoutes(amout)
    }
    const SetAmout = (q) => {
        let openingbal = 0;
        let sale = openingbal;
        let purchase = 0;
        if (debtarname == "sale") {
            // let stocktotal = [];
            prodata && prodata.forEach((dt) => {
                if (dt.name == particular && dt.voucher == "opening-balance") {
                    openingbal = dt.quaty
                }
                else if (dt.name == particular && dt.voucher == "sale") {
                    openingbal = openingbal - dt.quaty//-90
                } else if (dt.name == particular && dt.voucher == "purchase") {
                    openingbal = openingbal + dt.quaty//
                }
            })
            if (q > openingbal) {
                toast.warn(`Nagativ Stock ${openingbal - parseInt(q)}`)
            }
            setQuaty(q)
            let amout = rate * parseInt(q);
            setAmoutes(amout)
        }
        else {
            setQuaty(q)
            let amout = rate * parseInt(q);
            setAmoutes(amout)
        }
    }
    const DeleteBille = () => {
        billing.pop();
        let billdata = [...billing];
        setBilling(billdata);
    }
    var today = "";
    var setuTodat = "";
    if (!datelocal.includes('/') && datelocal != "") {
        var today = new Date(datelocal);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
        setuTodat = yyyy + '-' + mm + '-' + dd;
        // today=today.replace(/\//g, '-');
        // console.log(today);

    } else if (datelocal.includes('/')) {
        today = datelocal;
        setuTodat = yyyy + '-' + mm + '-' + dd;
    }
    else {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
        setuTodat = yyyy + '-' + mm + '-' + dd;
    }
    useEffect(() => {
        setNextFiled(nextfiled);
    }, [cranddr])
    let AddBilledata = (obj) => {
        if (obj) {
            obj.forEach((e, i) => {
                nextfiled[pandsid].bill.push(e)
                setCrandDr(nextfiled[pandsid].bill[i]);
            });
        }
    };
    const Journalnew = (e, ledgername, cad, index) => {
        if (e.key == "Tab" || e.key == "Enter") {
            if (ledgername == "" || ledgername == "purchase" || ledgername == "sale") {
                return;
            }
            if (cad == "credit") {
                nextfiled[index].amouttotal.credit = parseInt(e.target.value)
                nextfiled[index].amouttotal.debit = nextfiled[index].amouttotal.debit ? nextfiled[index].amouttotal.debit : 0
            } else if (cad == "debit") {
                nextfiled[index].amouttotal.credit = nextfiled[index].amouttotal.credit ? nextfiled[index].amouttotal.credit : 0
                nextfiled[index].amouttotal.debit = parseInt(e.target.value)
            }
            let totalcredit = 0, totaldebit = 0;
            nextfiled.forEach((obj) => {
                totalcredit = totalcredit + obj.amouttotal.credit
                totaldebit = totaldebit + obj.amouttotal.debit
            })
            // let name=nextfiled[index].ledger.name
            // let Endtotal=[];
            // setEndTotal({credit:totalcredit,debit:totaldebit});
            // Endtotal[name]={credit:totalcredit,debit:totaldebit}
            // setEndTotal(...endtotal,Endtotal);
            let cr_and_dr = [];
            let totalamount = 0
            if (totalcredit < totaldebit) {
                totalamount = totaldebit - totalcredit
                cr_and_dr = [...nextfiled, { state: "CR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "credit": totalamount, "debit": 0 } }]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(true)
            } else if (totalcredit > totaldebit) {
                totalamount = totalcredit - totaldebit
                cr_and_dr = [...nextfiled, { state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "debit": totalamount, "credit": 0 } }]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(true)
            } else {
                cr_and_dr = [...nextfiled]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(false)
                JournalGet();
            }
        }
    }
    const CrandDr = (val, index) => {
        nextfiled[index].state = val;
        nextfiled[index].amouttotal.credit = 0;
        nextfiled[index].amouttotal.debit = 0;
        setCrandDr(nextfiled[index].state);
    }
    const Debtarname = (val, index) => {
        let data = val != "" ? JSON.parse(val) : {};
        setPandSid(index)
        setDebtarname(data);
        nextfiled[index].ledger = data;
        setCrandDr(nextfiled[index].ledger)
        if (data.under == "purchase" || data.under == "sale") {
            $('#purchaseandsale').modal('show');
        }
    }
    const purchaseandsale = () => {
        let par = JSON.parse(particular);
        var testRowIndex = nextfiled[pandsid].bill.push(
            {
                id: par.id,
                particular: par.name,
                rate: rate,
                quaty: quaty,
                Amout: Amout,
                unit: unit

            }
        ) - 1;

        setCrandDr(nextfiled[pandsid].bill[testRowIndex])
        setProdata([])
        setUnit("")
        setParticular("");
        setAmoutes(0);
        setQuaty(0);
        setRate(0);

    }
    const totalamount = () => {
        if (debtarname.under == "purchase" || debtarname.under == "sale") {
            let totalamount = 0
            nextfiled[pandsid].bill.forEach((val) => {
                if (val.Amout != "") {
                    totalamount = totalamount + parseInt(val.Amout);
                }
            })
            setCrandDr(nextfiled[pandsid].amouttotal.credit = parseInt(totalamount));
            setCrandDr(nextfiled[pandsid].amouttotal.debit = parseInt(totalamount));
            if (nextfiled[pandsid].state == "CR") {
                nextfiled[pandsid].amouttotal.credit = parseInt(totalamount);
                nextfiled[pandsid].amouttotal.debit = 0;
            } else if (nextfiled[pandsid].state == "DR") {
                nextfiled[pandsid].amouttotal.credit = 0;
                nextfiled[pandsid].amouttotal.debit = parseInt(totalamount);
            }
            let totalcredit = 0, totaldebit = 0;
            nextfiled.forEach((obj) => {
                totalcredit += obj.amouttotal.credit
                totaldebit += obj.amouttotal.debit
            })
            // let endtotal=[name]={credit:totalcredit,debit:totaldebit}
            // console.log(totalcredit,totaldebit);
            // setEndTotal({credit:totalcredit,debit:totaldebit});
            let cr_and_dr = [];
            if (totalcredit < totaldebit) {
                let totalamount = totaldebit - totalcredit
                cr_and_dr = [...nextfiled, { state: "CR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "credit": totalamount, "debit": 0 } }]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(true)
            } else if (totalcredit > totaldebit) {
                let totalamount = totalcredit - totaldebit
                cr_and_dr = [...nextfiled, { state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "debit": totalamount, "credit": 0 } }]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(true)
            } else {
                cr_and_dr = [...nextfiled]
                setNextFiled(cr_and_dr)
                setDataSaveConfirm(false)

            }
            nextfiled[pandsid].totalamount = totalamount;
            setCrandDr(nextfiled[pandsid].amouttotal);
        }

    }
    const purchaseandsaleDelete = () => {
        let deletefield;
        deletefield = nextfiled[pandsid].bill.pop();
        setCrandDr(nextfiled[pandsid].bill[deletefield])
    }

    const NextfiledDelete = () => {
        if (nextfiled.length == "1") {
            setNextFiled([{ state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "debit": 0, "credit": 0 } }]);
            setCrandDr(nextfiled);
            return;
        }
        let deletefield;
        deletefield = nextfiled.pop();
        setCrandDr(deletefield);

    }
    const DataSave = async () => {
        let endtotal = {};
        nextfiled.forEach((obj) => {
            let ledgerName = obj.ledger.id;
            endtotal[ledgerName] = endtotal[ledgerName] || { credit: 0, debit: 0 };
            endtotal[ledgerName].credit += obj.amouttotal.credit || 0;
            endtotal[ledgerName].debit += obj.amouttotal.debit || 0;
        });

        let data = {
            bills: JSON.stringify(nextfiled),
            frm_id: auth.id,
            bos_no: bos_no,
            ledger_cr: "",
            ledger_dr: "",
            total: JSON.stringify(endtotal),
            date: today,
            slug: `journal-${bos_no}`,
            under: `journal`
        }
        nextfiled.forEach((obj) => {
            if (obj.state == 'CR') {
                data.ledger_cr = obj.ledger.id
            } else {
                data.ledger_dr = obj.ledger.id
            }
        })
        let res = await axios.post(`${url}/insert_journal/${auth.id}_journal`, data);
        if (res.data) {
            nextfiled && nextfiled.forEach(async (objs) => {
                if (objs.ledger.under == "sale" || objs.ledger.under == "purchase") {
                    let billing = objs.bill;
                    for (const billItem of billing) {
                        let billdata = {
                            date: today,
                            particulars: billItem.id,
                            rate: billItem.rate,
                            quantity: billItem.quaty,
                            unit: JSON.stringify({ ledger: objs.ledger.under, unit: billItem.unit }),
                            total: billItem.Amout,
                            frm_id: auth.id,
                            bill_id: `journal-${bos_no}`,
                            voucher: "journal"
                        };
                        await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata, {
                            headers: { 'Content-Type': 'application/json' },
                            timeout: 10000,
                        });
                    }

                } else {
                    const billdata = {
                        date: today,
                        particulars: null,
                        rate: null,
                        quantity: null,
                        unit: null,
                        total: null,
                        frm_id: auth.id,
                        bill_id: `journal-${bos_no}`,
                        voucher: "journal"
                    };
                    await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);

                }
            })
            setBosNo(0)
            setNextFiled([{ state: "DR", ledger: { id: "", name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { "credit": 0, "debit": 0 } }]);
            toast.success("data store successfuly")
            setCrandDr(nextfiled)
            setDataSaveConfirm(true)
            setLedger([]);

        }
    }
    const DataUpdate = async () => {
        let endtotal = {};
        nextfiled.forEach((obj) => {
            let ledgerName = obj.ledger.id;
            endtotal[ledgerName] = endtotal[ledgerName] || { credit: 0, debit: 0 };
            endtotal[ledgerName].credit += obj.amouttotal.credit || 0;
            endtotal[ledgerName].debit += obj.amouttotal.debit || 0;
        });
        let data = {
            bills: JSON.stringify(nextfiled),
            frm_id: auth.id,
            bos_no: bos_no,
            ledger_cr: "",
            ledger_dr: "",
            total: JSON.stringify(endtotal),
            date: today,
            slug: `journal-${bos_no}`,
            under: "journal"
        }
        nextfiled.forEach((obj) => {
            if (obj.state == 'CR') {
                data.ledger_cr = obj.ledger.id
            } else {
                data.ledger_dr = obj.ledger.id
            }
        })
        let res = await axios.put(`${url}/viewdataupdate/${auth.id}_journal/journal/${params.slug}`, data);
        if (res.data) {
            if (Array.isArray(billing) && billing.length > 0) {
                for (const billItem of billing) {
                    let billdata = {
                        date: today,
                        particulars: billItem.pro_id,
                        rate: billItem.rate,
                        quantity: billItem.quaty,
                        unit: billItem.unit,
                        total: billItem.Amout,
                        frm_id: auth.id,
                        bill_id: `journal-${bos_no}`,
                        voucher: "journal"
                    };
                    await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata, {
                        headers: { 'Content-Type': 'application/json' },
                        timeout: 10000,
                    });
                }
                redirect("/")
            } else {
                const stock_id = JSON.parse(vocherdata.stock_ids);
                let stockid = stock_id[0];
                const billdata = {
                    date: today,
                    particulars: null,
                    rate: null,
                    quantity: null,
                    unit: null,
                    total: null,
                    frm_id: auth.id,
                    bill_id: `journal-${bos_no}`,
                    voucher: "journal"
                };
                await axios.put(`${url}/update_stock/${auth.id}_stock/${stockid}`, billdata);
                redirect("/")
            }
        }
        // axios.put(`${url}/journal/${vocherdata.id}`, data).then((res) => {
        //     if (res.data) {
        //         redirect("/")
        //     }
        // })
    }
    const SetCreditandDebit = (val) => {
        setDataSaveConfirm(true)
    }
    const LedgerData = () => {
        setCrandDr({ state: "DR", ledger: { name: "", under: "" }, bill: [{ id: "", particular: "", rate: "", quaty: "", Amout: "" }], amouttotal: { credit: 0, debit: 0 } })
    }
    // const AddBillData = (objs) => {
    //     if (objs) {
    //         setBilling(objs);
    //     }
    // };
    const InputField = ({ state, type, value, defaultValue, onChange, onKeyDown }) => {
        if (state !== type) return null;

        return (
            <input
                type="number"
                className="col-12"
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
        );
    };
    return (
        <div>
            <div className='row '>
                <div className='col-12'>
                    <div className='container'>
                        <div className='row'>
                            <table className='mb-3 mt-3'>
                                <thead>
                                    <tr>
                                        <th>BOSNO:{bos_no}</th>
                                        <th className='d-flex justify-content-end'>Date:<input type="date" onChange={(e) => setDatelocal(e.target.value)} value={setuTodat} /></th>
                                        {/* {today} */}
                                    </tr>
                                </thead>
                            </table>
                            <div className="table-responsive">
                                <table className='table' >
                                    <thead className='border-top'>
                                        <tr className='row'>
                                            <th className='col-10 '>Particulars</th>
                                            <th className='col-1 ' >Debit</th>
                                            {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                                            <th className='col-1 ' >Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            nextfiled && nextfiled.map((nf, index) =>
                                                <>
                                                    <tr className='row'>
                                                        <td className='col-md-10 '>
                                                            <select onChange={(e) => CrandDr(e.target.value, index)} disabled={nf.totalamount ? true : false}>
                                                                {<option value={nf.state}>{nf.state}</option>}
                                                                <option value={"CR"}>CR</option>
                                                                <option value={"DR"}>DR</option>
                                                            </select>
                                                            <select onChange={(e) => Debtarname(e.target.value, index)} value={nf.ledger.name} >
                                                                <option value={nf.ledger.name ? nf.ledger.name : ""} >{nf.ledger.name ? nf.ledger.name : "--Select--"}</option>
                                                                {
                                                                    ledger && ledger.map((debdata) => <>
                                                                        <option value={JSON.stringify({ id: debdata.id, name: debdata.name, under: debdata.under })}>{debdata.name}</option>
                                                                    </>
                                                                    )
                                                                }
                                                            </select>
                                                            
                                                            <button data-bs-toggle="modal" data-bs-target="#debtoradd">+</button>
                                                            {/* {
                                                            nf.ledger == "purchase" || nf.ledger == "sale" ? <>
                                                                <button type="button" data-bs-toggle="modal" data-bs-target="#purchaseandsale" data-bs-whatever="@mdo" onClick={() => setPandSid(index)} disabled={nf.totalamount ? true : false}>+</button>
                                                            </> : null
                                                        } */}
                                                            {
                                                                nf.ledger.under == "purchase" || nf.ledger.under == "sale" ? <>
                                                                    <div className='col-4 offset-1'>
                                                                        <table className='table'>
                                                                            <tr>
                                                                                <th>item</th>
                                                                                <th>Qty:</th>
                                                                                <th>Unit:</th>
                                                                                <th>Rate:</th>
                                                                                <th>Amount:</th>
                                                                            </tr>

                                                                            {
                                                                                nf.bill.map((item) =>
                                                                                    <tr >
                                                                                        <td className='flex-grow-1'>{item.particular}</td>
                                                                                        <td className='flex-grow-1'>{item.quaty}</td>
                                                                                        <td className='flex-grow-1'>{item.unit}</td>
                                                                                        <td className='flex-grow-1'>{item.rate}</td>
                                                                                        <td className='flex-grow-1'>{item.Amout}</td>
                                                                                    </tr>)

                                                                            }
                                                                        </table>
                                                                    </div>
                                                                </> : null
                                                            }

                                                        </td>

                                                        <td className="col-md-1">
                                                            <InputField
                                                                state={nf.state}
                                                                type="DR"
                                                                value={nf.totalamount}
                                                                defaultValue={nf.amouttotal.debit}
                                                                onChange={(e) => SetCreditandDebit(e.target.value)}
                                                                onKeyDown={(e) => Journalnew(e, debtarname, "debit", index)}
                                                            />
                                                        </td>

                                                        <td className="col-md-1">
                                                            <InputField
                                                                state={nf.state}
                                                                type="CR"
                                                                value={nf.totalamount}
                                                                defaultValue={nf.amouttotal.credit}
                                                                onChange={(e) => SetCreditandDebit(e.target.value)}
                                                                onKeyDown={(e) => Journalnew(e, debtarname, "credit", index)}
                                                            />
                                                        </td>


                                                    </tr>

                                                </>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='d-flex justify-content-end m-2'>
                                {
                                    vocherdata && vocherdata.length != 0 ? <>
                                        <button className='btn btn-success' disabled={datasaveconfirm} onClick={DataUpdate}><i class="bi bi-arrow-repeat"></i>UPDATE</button>&nbsp;
                                        <button className='btn btn-dark' onClick={() => update_and_back(backComponet)}><i class="bi bi-arrow-left-short"></i>Back</button> </>
                                        : <button className='btn btn-primary' disabled={datasaveconfirm} onClick={DataSave}><i class="bi bi-floppy-fill"></i>SAVE</button>
                                }

                                &nbsp;
                                <button className='btn btn-danger' onClick={() => NextfiledDelete()}><i class="bi bi-arrow-up"></i>DELETE</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="modal fade" id="purchaseandsale" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Product Adder</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className="mb-3">
                                    <label class="form-label">Particulars</label>
                                    <select onChange={(e) => SetParRate(e.target.value)} className='form-control'>
                                        <option value="">--Select--</option>
                                        {
                                            prodata && prodata.map((par) =>
                                                <>
                                                    <option value={JSON.stringify({ id: par.id, name: par.name })}>{par.name}</option>
                                                </>
                                            )

                                        }
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Quantity</label>
                                    <input className='number' class="form-control" value={quaty} onChange={(e) => SetAmout(e.target.value)} ></input>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Unit</label>
                                    <input type='text' className='form-control' disabled value={unit}></input>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Rate</label>
                                    <input className='form-control' value={rate} onChange={(e) => SetRateItem(e.target.value)}></input>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Amount</label>
                                    <input className='form-control' value={Amout} disabled></input>
                                </div>
                            </div>



                            <button class="btn btn-primary" onClick={purchaseandsale}>Add</button>&nbsp;
                            <button class="btn btn-danger" onClick={purchaseandsaleDelete}>Delete</button>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={totalamount} data-bs-dismiss="modal">AllSet</button>
                        </div>
                    </div>

                </div>

            </div>

            <div class="modal fade" id="debtoradd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <LedgerPop url={url} onChildStateChange={LedgerData} />
            </div>
            {/* </div> */}
        </div >
    )
}

export default Journal