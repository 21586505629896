import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { ToWords } from 'to-words';
import GlobalKeyListener from '../../GlobalKeyListener';
import { useUser } from '../../Authdata';
import PaymentIN_invoice_one from './PaymentIN_invoice_one';
import Invoiceaddpanel from '../Invoiceaddpanel';
import LedgerPop from '../LedgerPop';
import Select from 'react-select'
function PaymentIN({ url, updateinfo, update_and_back, backComponet }) {
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const { auth } = useUser();
    const [voucherData, setVoucherData] = useState(null);
    const [ledger, setLedger] = useState({});
    const [ledgerData, setLedgerData] = useState([]);
    const [ledgerDataTop, setLedgerDataTop] = useState([]);
    const [amount, setAmount] = useState(0);
    const [receiptControl, setReceiptControl] = useState("");
    const [confirmSave, setConfirmSave] = useState(true);
    const [billing, setBilling] = useState([]);
    const [newBill, setNewBill] = useState(true);
    const [dateLocal, setDateLocal] = useState("");
    const [recNo, setRecNo] = useState(0);
    const [ledgercheck, setLedgerChecker] = useState("");
    const printRef = useRef();
    const [error, setError] = useState({
        ledger: "",
        amount: "",
        date: ""
    });
    const [deleteIndex, setDeleteIndex] = useState(null);
    const params = updateinfo;
    const toWords = new ToWords();
    const redirect = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params && params.slug) {
                    const res = await axios.get(`${url}/viewdata/${params.slug}/${auth.id}_pi_po/${auth.id}_stock/payment_in`);
                    if (res.data) {
                        const responsLedgerCr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${res.data.ledger_cr}`);
                        const responsLedgerDr = await axios.get(`${url}/show_ledger/${auth.id}_ledger/${res.data.ledger_dr}`);
                        const ledgerCrdata = responsLedgerCr.data;
                        const ledgerDrdata = responsLedgerDr.data;
                        setVoucherData(res.data);
                        
                        // const ledgertopoptions = ledgerCrdata.filter(led => led.under === "cash" || led.under === "bank")
                        //     .map((objled) => ({
                        //         value: JSON.stringify({ id: objled.id, name: objled.name }),
                        //         label: objled.name
                        //     }));
                        // setLedgerDataTop(ledgertopoptions);
                        // const ledgeroption = ledgerCrdata.map((ledg) => ({
                        //     value: JSON.stringify({ id: ledg.id, name: ledg.name, under: ledg.under }),
                        //     label: ledg.name
                        // }));
                        // setLedgerData(ledgeroption);

                        setLedger({ id: ledgerCrdata.id, name: ledgerCrdata.name, under: ledgerCrdata.under });
                        setAmount(parseInt(res.data.total));
                        setDateLocal(res.data.date);
                        setRecNo(res.data.rec_no);
                        setReceiptControl(JSON.stringify({ id: ledgerDrdata.id, name: ledgerDrdata.name }));
                    }
                }

                const ledgerRes = await axios.get(`${url}/show_ledgers/${auth.id}_ledger`);
                const ledgertopoptions = ledgerRes.data.filter(led => led.under === "cash" || led.under === "bank")
                    .map((objled) => ({
                        value: JSON.stringify({ id: objled.id, name: objled.name }),
                        label: objled.name
                    }));
                setLedgerDataTop(ledgertopoptions);
                const ledgeroption = ledgerRes.data.map((ledg) => ({
                    value: JSON.stringify({ id: ledg.id, name: ledg.name, under: ledg.under }),
                    label: ledg.name
                }));
                setLedgerData(ledgeroption);


                if (!params && voucherData === null) {
                    getPaymentIn();
                    setDateLocal(formatDate(new Date()));

                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [newBill, params && params.slug]);
    const getPaymentIn = async () => {
        try {
            // Fetch data from the API
            const res = await axios.get(`${url}/payment-in/${auth.id}_pi_po`);
            // Ensure data is available and is an array
            if (res.data && Array.isArray(res.data)) {
                // Find the highest `rec_no` value, default to 0 if none exist
                const highestRecNo = res.data.reduce((max, rec) => {
                    const recNo = rec.rec_no == null ? 0 : parseInt(rec.rec_no);
                    return recNo > max ? recNo : max;
                }, 0);

                // Set `recNo` to be the highest value + 1
                setRecNo(highestRecNo + 1);
            }

        } catch (error) {
            // Log the error for debugging purposes
            console.error("Error fetching payment-in data", error);
        }
    };

    const formatDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${dd}/${mm}/${yyyy}`;
    };

    async function PaymentINSave() {
        let validation = {};
        const localDate = new Date(dateLocal);
        const authDate = new Date(auth.date);

        if (localDate <= authDate) {
            validation.date = "Transaction date can't be prior to Opening Date";
        }
        if (ledger && Object.keys(ledger).length === 0) {
            validation.ledger = "Particular is required";
        }
        if (amount !== undefined && amount === 0) {
            validation.amount = "Amount is required";
        }
        if (receiptControl.trim() === "") {
            validation.receiptControl = "Ledger is required";
        }
        setError(validation);

        if (Object.keys(validation).length === 0) {
            let receiptLedger = JSON.parse(receiptControl);
            const data = {
                bills: JSON.stringify({ bill: JSON.stringify(billing),indireact_and_direact:"[]"}),
                ledger_cr: ledger.id,
                ledger_dr: receiptLedger.id,
                total: amount,
                date: date,
                frm_id: auth.id,
                rec_no: recNo,
                slug: `receipt-${recNo}`,
                under: 'payment_in'
            };
            try {
                let ReceiptResponse = await axios.post(`${url}/insert_payment-in/${auth.id}_pi_po`, data);
                if (ReceiptResponse.data) {
                    if (Array.isArray(billing) && billing.length > 0) {
                        // If billing is a non-empty array, iterate over each bill
                        for (const bill of billing) {
                            const billdata = {
                                date: date,
                                particulars: bill.pro_id,
                                rate: bill.rate,
                                quantity: bill.quaty,
                                unit: bill.unit,
                                total: bill.Amout,
                                frm_id: auth.id,
                                bill_id: `receipt-${recNo}`,
                                voucher: "payment_in"
                            };
                            await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);
                        }
                    } else {
                        // If billing is an empty array or not provided, handle this case
                        const billdata = {
                            date: date,
                            particulars: null,
                            rate: null,
                            quantity: null,
                            unit: null,
                            total: amount,
                            frm_id: auth.id,
                            bill_id: `receipt-${recNo}`,
                            voucher: "payment_in"
                        };
                        await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);
                    }

                    setConfirmSave(false);
                    toast.success("Data saved successfully");
                }
            } catch (error) {
                console.error("Error saving payment in data", error);
            }
        }
    };

    const PaymentINUpdate = async () => {
        const receiptLedger = JSON.parse(receiptControl);
        const data = {
            bills: JSON.stringify({ bill: JSON.stringify(billing) ,indireact_and_direact:"[]"}),
            ledger_cr: ledger.id,
            ledger_dr: receiptLedger.id,
            total: amount,
            date: date,
            frm_id: auth.id,
            rec_no: recNo,
            slug: `receipt-${recNo}`
        };
        try {
            let res = await axios.put(`${url}/viewdataupdate/${auth.id}_pi_po/payment_in/${params.slug}`, data);
            if (res.data) {
                if (Array.isArray(billing) && billing.length > 0) {
                    if (!Array.isArray(billing) || billing.length === 0) {
                        toast.error("Voucher is Empty");
                        return;
                    }
                    const promises = billing.map(async (item) => {
                        if (!('stockid' in item)) {
                            let billdata = {
                                date: date,
                                particulars: item.pro_id,
                                rate: item.rate,
                                quantity: item.quaty,
                                unit: item.unit,
                                total: item.Amout,
                                frm_id: auth.id,
                                bill_id: `receipt-${recNo}`,
                                voucher: "payment_in"
                            };
                            await axios.post(`${url}/insert_stock/${auth.id}_stock`, billdata);
                            update_and_back(backComponet)

                        }
                    });
                    await Promise.all(promises);
                } else {
                    const stock_id = JSON.parse(voucherData.stock_ids);
                    let stockid = stock_id[0];
                    const billdata = {
                        date: date,
                        particulars: null,
                        rate: null,
                        quantity: null,
                        unit: null,
                        total: amount,
                        frm_id: auth.id,
                        bill_id: `receipt-${recNo}`,
                        voucher: "payment_in"
                    };
                    let res = await axios.put(`${url}/update_stock/${auth.id}_stock/${stockid}`, billdata);
                    if (res.data) {
                        update_and_back(backComponet)
                    }

                }
            }
        } catch (error) {
            console.error("Error updating payment in data", error);
        }
    };

    const NewPaymentIn = (val) => {
        setConfirmSave(true);
        setAmount(val);
    };

    const printBill = useReactToPrint({
        content: () => printRef.current
    });
    const dateIsNotReq = () => {
        const date = new Date();
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };
    const LedgerIdentity = (val) => {
        let ledval = JSON.parse(val);
        setLedger(ledval);
        if (ledval.under === "purchase" || ledval.under === "sale") {
            $('#purchaseandsale').modal('show');
        }
    };
    const AddBillData = (objs) => {
        if (objs) {
            setBilling(objs);
        }
    };

    const AllSet = () => {
        if (ledger && (ledger.under === "purchase" || ledger.under === "sale")) {
            const total = billing.reduce((acc, val) => acc + val.Amout, 0);
            setAmount(total);
        }
    };

    const NewBill = () => {
        setNewBill(!newBill);
        setBilling([]);
        setLedger({});
        setAmount(0);
        setReceiptControl("");
        getPaymentIn();
        setConfirmSave(true);
    };
    // const wordsTotal = toWords.convert(amount ? amount : 0);
    let amouttotal = amount;
    var wordsTotal = toWords.convert(amouttotal, { currency: amouttotal % 1 !== 0 });
    wordsTotal = amouttotal % 1 !== 0 ?  wordsTotal :`Rupess ${wordsTotal}` ;
    let date = "";
    let setdate_input = "";
    if (!dateLocal.includes('/') && dateLocal != "") {
        date = new Date(dateLocal);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
        setdate_input = yyyy + "-" + mm + "-" + dd;

    } else if (dateLocal.includes('/')) {
        date = dateLocal;
    }
    else {
        date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        date = dd + '/' + mm + '/' + yyyy;
        setdate_input = yyyy + "-" + mm + "-" + dd;
    }
    useEffect(() => {
        AllSet();
    }, [deleteIndex, billing])
    const deleteItem = (index) => {
        setDeleteIndex(index);
    }
    const ReceiptControl = (val) => {
        setReceiptControl(val);
        let control = JSON.parse(val);
        setLedgerChecker(control.name)
    }
    
    return (
        <div>
            <GlobalKeyListener onSave={PaymentINSave} onPrint={printBill} />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8 offset-md-2'>
                        <div className="card text-dark bg-light mb-3">
                            <div className="card-header text-center text-md-start">
                                {/* <select
                                    className='mb-3 mb-md-0'
                                    onChange={(e) => ReceiptControl(e.target.value)}
                                    value={receiptControl}
                                >
                                    <option value={""}>{"--Select--"}</option>
                                    {ledgerData.filter(led => led.under === "cash" || led.under === "bank")
                                        .map((objled, index) =>
                                            <option key={index} value={JSON.stringify({ id: objled.id, name: objled.name })}>{objled.name}</option>
                                        )
                                    }
                                </select> */}
                                <div className='col-3'>
                                    <Select
                                        options={ledgerDataTop}
                                        onChange={(selectedOption) => ReceiptControl(selectedOption ? selectedOption.value : '')}
                                        value={ledgerDataTop.find(option => option.value === receiptControl) || null}
                                        placeholder={'--Select--'}
                                        isClearable
                                    />
                                </div>
                                <div>{error.receiptControl && <span className='text-danger'>{error.receiptControl}</span>}</div>
                                <span className='offset-md-7 fw-bold d-md-flex d-md-inline justify-content-center'>
                                    DATE:<input type='date' onChange={(e) => setDateLocal(e.target.value)} min={dateIsNotReq} value={setdate_input || dateIsNotReq()} />
                                </span>
                                {error.date && <div className='offset-md-7 d-md-flex d-md-inline justify-content-center text-danger'>{error.date}</div>}
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-8 text-center text-md-start'>
                                        <h6>Particulars</h6>
                                        {/* <select onChange={(e) => LedgerIdentity(e.target.value)} value={ledger}>
                                            {ledger.name ? <option>{ledger.name}</option> : <option>--Select--</option>}
                                            {

                                                ledgerData.map((res, index) =>
                                                    ledgercheck === res.name ? null : <option key={index} value={JSON.stringify({ id: res.id, name: res.name, under: res.under })}>{res.name}</option>
                                                )
                                            }
                                        </select> */}
                                        <div className='row'>
                                            <div className='col-4'>
                                                <Select
                                                    options={ledgerData}
                                                    onChange={(selectedOption) => LedgerIdentity(selectedOption ? selectedOption.value : '')}
                                                    value={ledgerData.find(option => option.value === JSON.stringify(ledger)) || null}
                                                    placeholder={'--Select--'}
                                                    isClearable
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#addledger">
                                                    <i class="bi bi-plus-lg"></i>
                                                </button>
                                            </div>
                                        </div>


                                        {error.ledger && <div className='text-danger'>{error.ledger}</div>}
                                    </div>
                                    <div className='col-md-4 text-sm-center text-center'>
                                        <h6>Amount</h6>
                                        <input type='number' onChange={(e) => NewPaymentIn(e.target.value)} value={amount}></input>
                                        {error.amount && <div className='text-danger'>{error.amount}</div>}
                                    </div>
                                </div>
                                {
                                    (ledger.under === "purchase" || ledger.under === "sale") &&
                                    <div className='row bg-white p-1 m-1 mt-2 border'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Particulars</th>
                                                    <th>Qty</th>
                                                    <th>Unit</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    billing.map((val, index) =>
                                                        <tr key={index}>
                                                            <td>{val.particular}</td>
                                                            <td>{val.quaty}</td>
                                                            <td>{val.unit}</td>
                                                            <td>{val.rate}</td>
                                                            <td>{val.Amout}</td>
                                                            <td>
                                                                <button className='btn-close' onClick={() => deleteItem({ stock_id: val.stockid, item_id: val.item_id })}></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                <hr />
                                <div className='row m-2'>
                                    {
                                        voucherData == null ? (
                                            <div className='col text-center'>
                                                <button className='btn btn-primary me-2' onClick={PaymentINSave} disabled={!confirmSave}><i class="bi bi-floppy-fill" ></i>&nbsp;Save</button>
                                                <button className='btn btn-primary me-2' onClick={printBill} id="printfun" disabled={confirmSave}><i class="bi bi-printer" ></i> Print</button>
                                                <button className='btn btn-dark me-2' onClick={NewBill} disabled={confirmSave}><i class="bi bi-file-earmark-plus"></i> New</button>
                                            </div>
                                        ) : (
                                            <div className='text-center'>
                                                <button className='btn btn-success' onClick={PaymentINUpdate}><i class="bi bi-arrow-repeat"></i>Update</button>&nbsp;
                                                <button onClick={() => update_and_back(backComponet)} className='btn btn-dark'><i class="bi bi-arrow-left-short"></i>Back</button>&nbsp;
                                                <button className='btn btn-primary me-2' onClick={printBill} id="printfun"><i class="bi bi-printer"></i>Print</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-10 d-flex justify-content-center mt-5 mb-5 offset-md-1'>
                        <div>
                            <div className="card" ref={printRef}>
                                <PaymentIN_invoice_one url={url} recNo={recNo} date={date} ledger={ledger.name} amount={amount} wordsTotal={wordsTotal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="modal fade" id="purchaseandsale" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Product Adder</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <Invoiceaddpanel adbilldata={AddBillData} url={url} localdate={null} vocheri={"receipt"} settingcontrol={setting} billNo={null} newbill={newBill} debitornamesetup={null} setupledcranddr={null} voucherupdate={null} indireactanddireact={null} billcontroler={null} billSaleset={null} deletebillItem={deleteIndex} deleteItem={deleteItem} updateinfo={updateinfo} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={AllSet} data-bs-dismiss="modal">AllSet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="addledger" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <LedgerPop url={url} onChildStateChange={NewBill} />
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default PaymentIN;
