import React, { useEffect, useState } from 'react'
// import './Invoice.css';
import { useUser } from '../../Authdata';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
function Invoice_two({ url, bosNo, datelocal, billing, alldataIndireact_direact, discount, total, wordstotal, debtarname, totalamount, deleteIndex, editItem, BillControler, invoicdesc,invoiceAlignmentmeng }) {
    const { auth } = useUser();
    const [deleteIndexno, setDeleteIndexNo] = useState("");
    deleteIndex(deleteIndexno);
    // console.log(billing)
    const editbillItem=(val)=>{
        editItem(val,"editstart")
    }
    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-md-5 border border-dark">
                    <div className='row p-2 text-start'>
                        {/* Uncomment and ensure the image path is correct */}
                        {/* <img src={`${url}/upload/${auth.logo}`} alt={`${auth.logo}`} height="100px" width="100px" /> */}
                        <p>
                            <b>{auth.frm_name}</b><br />
                            {auth.address}<br />
                            {auth.mobile} || &nbsp;
                            {auth.email}<br />

                            {/* Sunnyvale, CA 94085 */}
                        </p>
                    </div>

                    <div className='row border-top border-dark p-2 text-start'>
                        Buyer:
                        <p>
                            <b>{BillControler === "cash" ? "Cash" : debtarname.name}</b><br />
                            {BillControler !== "cash" && (debtarname.address || debtarname.account)}<br />
                            {BillControler !== "cash" && (debtarname.phoneno || debtarname.ifsc)}
                        </p>
                    </div>
                </div>

                <div className="col-md-7 border border-dark">
                    <div className="row table-responsive">
                        <table className="table  w-100 m-0" cellSpacing="0" cellPadding="10">
                            <tbody>
                                <tr>
                                    <td className="border-dark w-50 text-start">
                                        <b className='d-block'>Invoice No</b>
                                        <span>{bosNo}</span>
                                    </td>
                                    <td className="border-start  border-dark w-50 text-start">
                                        <b className='d-block'>Dated</b>
                                        <span>{datelocal}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='border-dark w-50 text-start'>
                                        <b className='d-block'>Delivery Note</b>
                                        <span></span>
                                    </td>
                                    <td className='border-start border-dark w-50 text-start'>
                                        <b className='d-block'>Mode/Terms of Payment</b>
                                        <span>{BillControler === "cash" ? "Cash" : "Credit"}</span>
                                    </td>
                                </tr>
                                {/* <tr>
                                <td className="border-bottom border-dark w-50">
                                    <b>Invoice No</b>
                                    <span></span>
                                </td>
                                <td className="border-start border-bottom border-dark w-50">
                                    <b>Dated</b>
                                    <span></span>
                                </td>
                            </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row table-responsive">
                <table className="invoice-bill w-100">
                    <tbody>
                        <tr>
                            <td className="border border-1 border-dark"><b>SL. No.</b></td>
                            <td className="border border-1 border-dark"><b>Particulars</b></td>
                            <td className="border border-1 border-dark"><b>Qty</b></td>
                            <td className="border border-1 border-dark"><b>Rate</b></td>
                            <td className="border border-1 border-dark"><b>Per</b></td>
                            <td className="border border-1 border-dark"><b>Amount</b></td>
                        </tr>
                        <tr>
                            <td className='border border-end border-dark'>
                                {billing && billing.map((obj, index) =>
                                    <p key={index}>{index + 1}</p>
                                )}
                                {alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                    <p key={index}>&nbsp;</p>
                                )}
                            </td>
                            <td>
                                <div className="particular-items">
                                    {billing && billing.map((obj) =>
                                        <p key={obj.id}>{obj.particular}</p>
                                    )}
                                    {alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                        <p key={index} className='text-end'>{obj.particular}</p>
                                    )}
                                </div>
                            </td>
                            <td className='border border-end border-dark'>
                                <div className="particular-items amount-items">
                                    {billing && billing.map((obj) =>
                                        <p key={obj.id}>{obj.quaty}</p>
                                    )}
                                </div>
                                {alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                    <p key={index}>&nbsp;</p>
                                )}
                            </td>
                            <td className='border border-end border-dark'>
                                <div className="particular-items amount-items">
                                    {billing && billing.map((obj) =>
                                        <p key={obj.id}>{obj.rate}</p>
                                    )}
                                    {alldataIndireact_direact && alldataIndireact_direact.map((item, index) =>
                                        <p key={index} className='text-end'>
                                            {item.percentage ? `${item.percentage}%` : null}
                                        </p>
                                    )}
                                </div>
                            </td>
                            <td className='border border-end border-dark'>
                                <div className="particular-items amount-items">
                                    {billing && billing.map((obj) =>
                                        <p key={obj.id}>{obj.unit ? obj.unit : <span>&nbsp;</span>}</p>
                                    )}
                                </div>
                                {alldataIndireact_direact && alldataIndireact_direact.map((obj, index) =>
                                    <p key={index}>&nbsp;</p>
                                )}
                            </td>
                            <td className='border border-end border-dark'>
                                <div className="particular-items amount-items">
                                    {billing && billing.map((obj, index) =>
                                        <p key={obj.id}>&#8377;{obj.Amout}
                                            <span>
                                                <Link className='hide-print text-dark' onClick={() => setDeleteIndexNo({ stock_id: obj.stockid ? obj.stockid : null, pro_id: obj.pro_id, index, item_id: obj.item_id })}><i className='bi bi-trash-fill'></i></Link>
                                                &nbsp;
                                                <Link className='hide-print text-dark' onClick={() => editbillItem({ stock_id: obj.stockid ? obj.stockid : null, pro_id: obj.pro_id, index, item_id: obj.item_id})}><i className='bi bi-pen-fill'></i></Link>
                                            </span>
                                        </p>
                                    )}
                                    {alldataIndireact_direact && alldataIndireact_direact.map((item, index) =>
                                        <p key={index}>&#8377;{item.percentage ? discount : item.amount}</p>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='border border-1 border-dark'></td>
                            <td className='border border-1 border-dark'><b>Total</b></td>
                            <td className='border border-1 border-dark'><b></b></td>
                            <td className='border border-1 border-dark'><b></b></td>
                            <td className='border border-1 border-dark'><b></b></td>
                            <td className='border border-1 border-dark'>&#8377;{totalamount !== 0 ? totalamount : total}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='row border border-dark text-start'>
                <div className="col-12">
                    <div>Amount chargeable (in words)</div>
                    <b>{wordstotal}</b>
                    {/* Uncomment and fill in with actual details if needed */}
                    {/* <p className="mt-50">Entry Id: <b>CORP 3793457</b></p>
            <p>In case you wish to pay us through ACH / Wire Transfer.<br /> Refer our bank details below</p>
            <p className="bank-details">
                <span>Bank Name: XYZ Bank</span><br />
                <span>Beneficiary Name: XYZ Bank</span><br />
                <span>A/C No: 6563563466</span><br />
                <span>ABA/Routing No: 23232323</span><br />
                <span>Swift Code: 4545</span><br />
            </p> */}
                </div>
                <div className='col-md-6 mt-5'>
                    <div className={`d-${invoiceAlignmentmeng.show_desc}`}>
                        <h6 className='text-decoration-underline '>Description</h6>
                        <p>{invoicdesc}</p>
                    </div>
                </div>
                <div className="col-md-6 text-end mt-5">
                    <div>
                        <p>For {auth.frm_name}</p>
                        <span></span>
                        <p>Authorised Signature</p>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default Invoice_two